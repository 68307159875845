.blog-mainContainer {
  /* margin-top: 1%;
       margin-left: 2.5%; */
  background-color: rgb(0 0 0 / 3%) !important;

  /* margin: 1% 0 2% 2.5%; */
  background-color: #f8f8f8;
  padding: 2% 2% 5% 2%;
}

.blog-mainContainer h2 {
  font-family: AvenirDemi;
  margin-top: 2%;
  font-size: 1.5vw;
  font-weight: 700;
  font-style: normal;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
}

.blog-mainContainer h3 {
  font-family: AvenirDemi;
  margin-top: 2%;
  font-size: 1.4vw;
  font-weight: 700;
  font-style: bold;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 1%;
}

.blog-mainContainer h4 {
  font-family: AvenirDemi;
  margin-top: 2%;
  font-size: 1.1vw;
  font-weight: 700;
  font-style: bold;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 1%;
}

.blog-mainContainer h3 .blog-mainContainer h2,
span {
  font-family: AvenirDemi !important;
  /* color: #3d7cc5; */
}

.blog-mainContainer ol li h3 span {
  font-family: AvenirDemi !important;
  /* color: #3d7cc5 !important; */
  font-size: inherit !important;
}

.blog-mainContainer p {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw !important;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 1%;
}

.blog-mainContainer ol strong {
  font-family: AvenirDemi;
  margin-top: 2%;
  font-size: 0.9vw;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.01em;
  color: #000000;
}

.why-choose-subheader {
  margin: 3% 0 0 3%;
}

.why-choose-subheader li {
  font-family: AvenirDemi;
  font-size: 1vw !important;
  font-weight: 600;
  margin-top: 1%;
  margin-left: 3%;
}

.why-choose-subheader li .bulat-icon {
  font-size: 1vw;
}

.blog-mainContainer h1 {
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 700;
  margin-top: 2%;
}

/* .blog-mainContainer p {
       font-family: AvenirRegular;
       font-style: normal;
       font-weight: 400;
       font-size: 26px;
       line-height: 31px;
       letter-spacing: 0.01em;
  
       color: #000000;
  } */

.blog-mainContainer ul,
.blog-mainContainer ol {
  margin-left: 2%;
  font-family: AvenirRegular;
}

.blog-mainContainer ol ::marker {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
}

.blog-mainContainer ol li {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 0.9%;
}

.blog-mainContainer ol li strong {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
}

.blog-mainContainer ol li span {
  font-family: AvenirRegular !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw !important;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
}

.blog-mainContainer ul li {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 0.9%;
}

.blog-mainContainer ul li strong {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
}

.blog-mainContainer ul li span {
  font-family: AvenirRegular !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw !important;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .blog-mainContainer {
    padding: 2% 3% 2% 3%;
  }

  .blog-header-description {
    margin: 1% 0 4%;
  }

  .blog-mainContainer p {
    margin: 1% 0;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 3%;
  }

  .why-choose-subheader {
    margin: 5% 0 0 5%;
  }

  .why-choose-subheader li {
    font-size: 3vw !important;
    font-weight: 600;
  }

  .blog-mainContainer h1 {
    font-size: 16px;
  }

  .blog-mainContainer h2,
  .blog-mainContainer h3 {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 4%;
  }

  .blog-mainContainer h4 {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 4%;
  }

  .blog-mainContainer ul,
  .blog-mainContainer ol {
    margin-left: 5%;
  }

  .blog-mainContainer ol li {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 3%;
  }

  .blog-mainContainer ol li strong {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 31px;
    letter-spacing: 0.01em;
    color: #000000;
  }

  .blog-mainContainer ol li span {
    font-family: AvenirRegular !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #000000;
  }

  .blog-mainContainer ol ::marker {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 31px;
    letter-spacing: 0.01em;
    color: #000000;
  }

  .blog-mainContainer ul li {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 3%;
  }

  .blog-mainContainer ul li strong {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 31px;
    letter-spacing: 0.01em;
    color: #000000;
  }

  .blog-mainContainer ul li span {
    font-family: AvenirRegular !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #000000;
  }

  .blog-mainContainer ul li::marker {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.01em;
    color: #000000;
  }

}
