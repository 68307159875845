.react-multi-carousel-dot-list {
    position: relative !important;
}

.react-multiple-carousel__arrow {
    z-index: 0 !important;
}



.testimonial-card-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: inline-flex;
    width: 45vw;
    height: 37vh;
}

.testimonial-card-image {
    padding: 0;
    margin: 0;
}

.testimonial-card-container img {
    border-start-start-radius: 12px;
    border-end-start-radius: 12px;
    height: 100%;
    width: 11vw;
}

.testimonial-card-review {
    width: 100%;
    padding: 2% 5%;
}

.testimonial-review-date {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8vw !important;
    line-height: 14px;

    color: #525252;
}

.testimonial-review-heading {
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    font-size: 1.3vw !important;
    line-height: 31px;

    color: #525252;
    padding: 1% 0;
}

.testimonial-review-desc {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 1vw !important;
    line-height: 20px;

    color: #000000;
    /* text-align: justify; */
}

.testimonial-customer-name-container p {
    text-align: end;
    font-family: AvenirDemi;
    font-style: normal;
    font-weight: 700;
    font-size: 1vw !important;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #525252;
}

@media screen and (max-width:768px) {

    .testimonial-card-container {
        width: 92vw;
        height: 100%;
    }

    .testimonial-card-container img {
        width: 36vw;
    }

    .testimonial-review-date {
        font-style: normal;
        font-weight: 400;
        font-size: 3vw !important;
        line-height: 14px;

        color: #525252;
    }

    .testimonial-review-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 4vw !important;
        line-height: 19px;

        color: #525252;
        padding: 3% 0;
    }

    .testimonial-review-desc {
        /* font-style: normal;
        font-weight: 400;
        font-size: 2vw !important;
        line-height: 10px;

        color: #525252; */

        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: #000000;
    }

    .testimonial-customer-name-container p {
        text-align: end;
        font-family: AvenirDemi;
        font-style: normal;
        font-weight: 700;
        font-size: 3vw !important;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #525252;
    }
}