.download-app-container {
  margin-top: 1%;
  margin-left: 2.5%;
  background-color: #f8f8f8;
  padding: 2% 6.2% 0% 2%;
}
.blog-header h4 {
  margin-top: 2%;
  font-size: 1.3vw;
  font-weight: 700;
  font-style: normal;
  color: #122164;
  margin-bottom: 2%;
}

.blog-header-description p {
  font-size: 1vw;
  font-weight: 400;
  
}
.barCodeAndGooglePlay {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  height: 80%;
 
}

.barCode {
  width: 30%;
  /* height: 20%; */
  padding: 1%;
  border: 2px solid black;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.barCode::before {
  content: "";
  position: absolute;
  background-color: #f8f8f8;
  display: flex;
  align-self: center;
  width: 70%;
  height: 110%;
}

.barCode::after {
  content: "";
  position: absolute;
  background-color: #f8f8f8;
  display: flex;
  align-self: center;
  width: 110%;
  height: 70%;
}

.barCode img {
  z-index: 1;
  width: 100%;
}
.googlePlay {
  width: 40%;
  height: 20%;
}


.googlePlay img {
  width: 100%;
  height: "auto";
  margin-right: 24%;
}
.gativanApp {
  padding: 0% !important;
  padding-top: 1% !important;
  height: 100% !important;
  background-color: black !important;
  border-radius: 8% 8% 0px 0px !important;
}

.gativanApp img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .blog-scanAndGativanApp {
    display: none;
  }


  .download-app-container {
   
    padding: 2% 8% 0% 2%;
  }


  .blog-header h4 {
    font-size: 16px;
    font-weight: 700;
  }

  .blog-header-description p {
    font-size: 3vw;
    font-weight: 400 !important;
  }
  .barCodeAndGooglePlay {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    position: relative;
    /* height: 100%; */
    margin-bottom: 9%;
    margin-left: 6%;
    padding-bottom: 2%;
  }

  .gativanApp {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0% !important;
    padding-top: 1% !important;
    width: 65%;
    height: 100% !important;
    background-color: black !important;
    border-radius: 8% 8% 0px 0px !important;
    margin: 0% 22% !important;
  }

  .gativanApp img {
    width: 100%;
    height: 100%;
 
  }
  .googlePlay {
    width: 50%;
    height: 25%;
  }

  .googlePlay img {
    width: 100%;
    height: "auto";
    margin-left: 30%;

  }
}
