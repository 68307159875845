* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------------workshop_container----------------------*/
.workshop_container {
  margin: 5%;
}
/*-------------gativan workshop-------------- */
.workshop {
  text-align: left;
  margin: 1% 0%;
  font-weight: bold;
}
/*---------------locations box-------------*/
.loactions_box {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}
.heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gativan {
  color: #122164;
  font-size: 25px;
  font-weight: 700;
  margin-left: 2%;
}
.garage-name {
  font-size: 15px;
  font-weight: 500;
  /* float: left; */
  margin: 2% 6%;
  text-align: justify;
}
.garage-info {
  display: flex;
  flex-direction: row;
  justify-content: first baseline;
  text-align: justify;
  
  padding: 2% 2%;
}
.information {
  /* float: right; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  margin: 1% 2%;
  color: #000000;
  text-align: justify;
}

.service-location-btn a{
  text-transform: none !important;
  text-decoration: none !important;
}
.locator-book-now-btn {
  background-color: #122164 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  letter-spacing: 0.02em !important;
  color: white !important;
  text-transform: none !important;
  width: 96%;
  height: 15%;
  text-align: center !important;
  justify-content: center !important;
  align-items: center;
  padding: 2% !important;
  margin-left: 2% !important;
  text-decoration: none !important;
}
.service-location-btn{
  padding: 2%;
}
.services-btn {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  letter-spacing: 0.02em !important;
  text-transform: none !important;
  width: 97.2%;
  height: 4vh;
  border: 1px solid black;
  color: #000000 !important;
  text-decoration: none !important;
}
.location-btn {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  letter-spacing: 0.02em !important;
  text-transform: none !important;
  width: 98%;
  height: 4vh;
  color: #000000 !important;
  border: 1px solid black;

}
/*-------------map-----------------------*/
.map {
  margin-top: 2%;
  height: 100%;
  width:100%;
  background-color: #7b8bd4;
}
.map-locaton{
  height: 100%;
  width:100%
}
/*-----------------Mobile view started-------------------------*/
@media screen and (max-width: 768px) {
  .workshop_container {
    margin: 3%;
  }
  .services-btn  {
    border: 1px solid black !important;

  }
  .location-btn {
    border: 1px solid black !important;
  }
  .map-locaton{
    height: 100%;
    width:100%
  }
  .map {
    margin-top: 2%;
    height: 100%;
  }
}
