* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mobile-view-footer{
    position: sticky !important;
    bottom: 0px !important;
}

.addtocart_container {
    display: grid;
    grid-template-columns: 65% auto;
    grid-template-rows: 110px auto;
    margin: 0% 5%;
    /* overflow: hidden; */
}

/* -------------------------------------------------------------- */

.services-menu {
    width: 100%;
    height: auto;
    position: sticky;
    top: 6.2%;
    z-index: 1;
}

/* ----------------------------------------------------------- */
.change-vehicle {
    grid-column: 2/-1;
    grid-row: 1/-1;
    margin-left: 5%;
    padding: 2%;
}









/*--------------------our-services--------------------- */


/* --------------------------------addtocart-mainsection-------------------------------- */
.Trending-Services,
.how-its-done,
.blog-article {
    margin-top: 3%;
    height: 250px;
}

.Trending-Services {
    background-color: aqua;
}

.how-its-done {
    background-color: brown;
}

.blog-article {
    background-color: crimson;
}
 
 
/* ----------------------------------------------------------- */
@media screen and (max-width:768px) {

    
    .addtocart_container {
        grid-template-columns: 100%;
        grid-template-rows: 120px 130px auto;
        margin: 0% 2.5%;
    }

    .change-vehicle {
        grid-column: 1/-1;
        grid-row: 2/3;
        height: 100%;
        margin: 3% 0% 0% 0%;
        padding: 0;
    }


    .services-menu {
        position: sticky;
        top: 6.5%;
        z-index: 1;
    }




}