* {
    margin: 0;
    padding: 0;
}

.gativan-experts-container {
    padding: 5%;
    background: #F8F8F8;
    width: 100%;
    height: 100%;
}

.gativan-experts-profile-detail-container {
    display: flex;
    margin-bottom: 5%;
    /* height: 25vh; */
}

.gativan-experts-profile-container {
    width: 35%;
}

.gativan-experts-name-container h2 {
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.01em;

    color: #000000;
}

.gativan-experts-profile-container img {
    width: 100px;
}

.gativan-experts-name-container {
    width: 65%;
}

.rating-section {
    display: flex;
}

.gativan-experts-description p {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 137.19%;
    color: #000000;
}

.gativan-experts-details {
    margin-top: 2%;
}

.gativan-experts-details ul li {
    margin-left: 8%;
}

.gativan-experts-details ul li {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;

    color: #000000;

}

/* .gativan-experts-details ul {
    list-style: none;
}

.gativan-experts-details ul li::before {
    content: "\2022";
    color: #000000;
    font-weight: bold;
    display: inline-block;
    width: 1em;
} */


/* media query for laptop and tablet */
@media screen and (max-width:1440px) and (min-width:768px) {
    .gativan-experts-name-container h2 {
        font-size: 18px;
    }

    .gativan-experts-details ul li {
        font-size: 14px !important;
    }
}

@media screen and (max-width:768px) {
    .gativan-experts-name-container h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 137.19%;
        /* identical to box height, or 22px */

        letter-spacing: 0.02em;

        color: #000000;
    }

    .gativan-experts-profile-container {
        width: 42%;
    }

    .gativan-experts-name-container {
        width: 60%;
    }

    .gativan-experts-details ul li {
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 137.19%;
        /* or 16px */


        color: #000000;
    }

    .gativan-experts-description p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 137.19%;
        /* or 16px */


        color: #000000;
    }

    /* .gativan-experts-details ul li::before {
        content: "\2022";
        color: #000000;
        font-weight: bold;
        display: inline-block;
        width: 1em;
    } */
}