* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.Selection-header {
    margin-bottom: 0%;
}

.Selection-header h2 {
    font-family: AvenirBold !important;
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 0%;
}

.Selection-header-Buttons {
    text-align: center;
    margin-left: 3% !important;
}

.Selection-header-Buttons .MuiButton-root {
    padding: 0px 8px !important;
}

.Selection-header-Buttons Button {
    width: 85%;
    height: 100%;
    border-radius: 0px !important;
    padding: 4% 5%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: 5%;
    display: flex;
    justify-content: last baseline;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    font-family: AvenirBold !important;
    color: black;
    text-transform: none !important;
}

/* css for scroll bar */

/* width */
::-webkit-scrollbar {
    width: 5px;
}

::-moz-scrollbar {
    width: 5px;
}

::-ms-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;

}

::-moz-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
}

::-ms-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

::-moz-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

::-ms-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #E9EDFB;
}

::-moz-scrollbar-thumb:hover {
    background: #E9EDFB;
}

::-ms-scrollbar-thumb:hover {
    background: #E9EDFB;
}

.Selection-header-Buttons Button img {
    width: 25%;
    margin-right: 5%;
}

/* .css-1osj8n2-MuiGrid-root, .Selection-header-Buttons {
    display: flex ;
    justify-content: center;
} */


/* ----------------Search Box------------------- */

.SearchBox {
    border: 0.668254px solid #B1B1B1;
    border-radius: 4px;


    width: 100%;
    height: 40px;
    border: 1px solid #B1B1B1;
    display: flex;
    align-items: center;
    /* background-color: white !important; */
    padding: 0% 4%;
    margin: 5% 0;
}

.select-item h6 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.01em;

    color: #000000;
}

.select-item button {
    padding-right: 3%;
    background-color: white;
    border: none;
    outline: none;
    font-size: 26px;
}

.select-item button .arrow-back-icon {
    font-size: 20px;
}


/* ---------------select / Search car or bike-----------------------*/


.checkPrice_section input,
.SearchBox input {
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    /* background-color: white; */
}

.checkPrice_section input,
.SearchBox input :focus {
    border: none;
}

.checkPrice_section .Box span,
.select-vehicle span {
    margin-right: 2%;
    font-family: AvenirDemi;
}

.checkPrice_section .Box input {
    background-color: transparent;
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;

    opacity: 0.65;
}

.checkPrice_section Button {
    background-color: #212164;
    font-size: 24px;
    font-weight: 700;
}

.checkPrice_section .Box {
    width: 100%;
    height: 54px;
    border: 1px solid #B1B1B1;
    display: flex !important;
    align-items: center;
    padding: 0% 4%;
    margin-top: 5%;
}

.checkPrice_section .Box img {
    width: 100%;
}

.select-vehicle {
    width: 90% !important;
    height: 44px;
    border: 1px solid #B1B1B1;
    display: flex !important;
    align-items: center;
    padding: 0% 4%;
    margin: 5% 0 0 5%;
}

.select-vehicle img {
    width: 100%;
}

.checkPrice_section .bottumLeft .description {
    font-family: AvenirRegular;
}

.checkPrice_section .bottumRight img {
    width: 30%;
}

.checkPrice_section .bottumRight .description {
    font-family: AvenirRegular;
}

.check-price-link {
    text-decoration: none;
}

.check-price-button-container {
    text-align: center;
    margin-top: 5%;
}

.check-price-button-container button {
    padding: 3% 8% !important;
    background-color: #122164 !important;
    box-shadow: 0.668254px 3.34127px 6.68254px rgba(0, 0, 0, 0.15);
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
    text-transform: none !important;
}

.modal-header button,
.modal-header h6 {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: none !important;
    padding: 0px !important;
    text-align: start !important;
    /* identical to box height */


    color: #000000;

    text-align: start;

}



.varticalRow {
    width: 20px;
    height: 100%;
    background-color: black;
}

/* =-------------------Brand,Model,variant Section Card  -----------------------------*/

.main-selection-container{
    position: sticky;
    top: 8%;
}


.selection-sections {
    /* background-color: white !important; */
    /* padding: 4%; */
    height: 450px;
    position: relative;

}

.selction-cardSection {
    overflow: auto;
    height: 68%;
    overflow-x: hidden;
    /* background-color: #212164; */

}

.selction-cardSection .selection-card {
    height: 100px;
    overflow: hidden;

}

.selection-card-contain {
    margin: 0%;

    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s;
}

.selection-card-contain:hover {
    scale: 1.1;
}

.selection-card-contain-image {
    width: 80px;
    height: 65px;
}

.selction-cardSection .selection-card-contain img {
    width: 100%;
    height: 100%;
}

.selction-cardSection .selection-card-contain h4 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 5%;
    font-family: AvenirRegular;
}

/* ---------MOdal style--------------------- */

.Cart-Modal {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0% 4%;
}

.Cart-Modal .modal_body {
    width: 100%;
    border-radius: 2%;
    overflow: hidden;
    padding: 4%;
    background-color: #FFFF;
}


/* ----------------Cart Bottom-------------- */

.vertical_line {
    width: 2px;
    height: 95%;
    background-color: #707070;
}

.checkPrice-button {
    font-family: AvenirBold;
    font-size: 1.5vw !important;
    text-decoration: none !important;
    text-transform: none !important;
    background-color: rgb(123, 139, 212) !important;
    color: white !important;
}

/* -------------------- Rating ----------------- */
.rating h4 {
    font-size: 35px;
    font-family: AvenirBold;
}

.rating h4 font {
    font-size: 22px;
}

.rating-container a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

/* --------------------------------- skeleton -------------------- */
.toggle-skeleton-first {
    width: 50% !important;
    height: 40px !important;
    margin-right: 10% !important;
}

.select-vehicle-skeleton {
    margin: 20px 0 !important;
    width: 100% !important;
    height: 40px !important;
}

.gif-section img {
    width: 100% !important;
}

.rating-skeleton {
    width: 90% !important;
    height: 11vh !important;

}

/* media query for laptop and tablet */
@media screen and (max-width:1440px) and (min-width:768px) {
    .selection-sections {
        height: 350px;
    }

    .Selection-header h2 {
        font-size: 26px !important;
    }

    .Selection-header-Buttons Button {
        font-size: 16px !important;
        height: 100% !important;
        border-radius: 0px !important;
    }

    .checkPrice_section .Box {
        height: 40px;
    }

    .gif-container img {
        width: 100%;
    }

    .rating h4 {
        font-size: 25px;
    }

    .rating h4 font {
        font-size: 18px;
    }

    .gativan-experts-name-container h2 {
        font-size: 16px;
    }
}



@media screen and (max-width:768px) {

    .SearchBox input {
        font-family: AvenirRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #000000;

        opacity: 0.8;
    }

    .Selection-header-Buttons Button {
        font-size: 16px;
        font-family: AvenirBold;
    }


    .checkPrice_section Button {
        font-size: 20px;
        font-weight: 500;
    }

    .checkPrice-button {
        font-size: 16px !important;
    }

    .Selection-header h2 {
        font-size: 31px;
    }

    .checkPrice_section Button {
        font-size: 20px !important;
        background: #263577 !important;
        box-shadow: 0px 2px 10px rgba(62, 62, 62, 0.1) !important;
        border-radius: 2px !important;
    }

    .checkPrice_section .bottumRight img {
        width: 40%;
    }

    .selection-sections {
        height: 380px;
    }


    .selection-card-contain-image {
        width: 40px;
        height: 40px;
    }

    .selction-cardSection .selection-card-contain img {
        width: 100%;
    }

    .selction-cardSection .selection-card-contain h4 {
        font-size: 12px;
        font-weight: bold;
        font-family: AvenirRegular;
    }

    .select-item {
        align-items: baseline !important;
    }

    .select-item h6 {
        font-family: AvenirBold;
        font-style: normal;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 19px;

        color: #000000;
    }

    .select-item button {
        background-color: white;
        border: none;
        outline: none;
        font-size: 16px;
    }

    .select-item button .arrow-back-icon {
        font-size: 16px;
    }

    #mobile-number {
        border: 0.740181px solid #B1B1B1;
        border-radius: 2px;
        height: 44px;
    }

    #mobile-input {
        font-family: AvenirRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;

        color: #000000;

        opacity: 0.5;
    }

    /* -------------------- Rating ----------------- */

    .MuiTypography-root {
        font-size: 20px !important;
    }

    .rating h4 {
        font-size: 25px !important;
    }

    .rating h4 font {
        font-size: 18px !important;
    }


}