* {
    margin: 0;
    padding: 0;
}
.carousel-container {
    width: 98%;
    margin-left: 1%;
}
.carousel-content-container {
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
    border-radius: 8px;
    background-color: #122164;
}
.carousel-image-container {
    width: 100%;
    height: auto;
}
.carousel-image-container img {
    width: 100%;
    height: 100%;
}
.carousel-text-container {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #ffff;
    padding: 2% 0;
}
.carousel-text-container h4 {
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
}
.carousel-text-container p {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 14px;
    letter-spacing: 0.01em;
}

.Carousel li{
    margin: 0;
    padding: 0 0.5%;
}

/* .Carousel ul{
    transform: translate3d(0px, 0px, 0px);
} */

@media screen and (max-width:600px) {
    .carousel-container {
        width: 98%;
    }
    .carousel-content-container {
        width: 100%;
        height: 200px;
        overflow: hidden;
        border-radius: 8px;
        background-color: #122164;
    }
    .carousel-image-container {
        width: 100%;
        height: auto;
    }
    .carousel-image-container img {
        width: 100%;
        height: 100%;
    }
    
    .carousel-text-container {
        width: 100%;
        height: 100%;
        text-align: center;
        color: #ffff;
        padding: 4% 0;
    }
    .carousel-text-container h4 {
        font-family: 'Avenir Next LT Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
    }
    .carousel-text-container p {
        font-family:'Avenir Next LT Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 14px;
        letter-spacing: 0.01em;
    }

    /* .Carousel ul{
        transform: translate3d(50px, 0px, 0px) !important;
    } */
}