* {
    margin: 0;
    padding: 0;
  }
  
  .homepage-headers {
    position: relative;
  }
  
  .homepage-headers h2 {
    font-size: 42px;
    font-weight: bold;
    margin-left: 24px;
    margin-top: 32px;
    margin-bottom: 10px;
  }
  
  .homepage-headers h2::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0px;
    width: 14px;
    height: 30px;
    background-color: #212164;
  }
  
  .sub-heading {
    margin-left: 3%;
    font-family: AvenirRegular;
  }
  
  .grid-link {
    text-decoration: none;
  }
  
  .search-container {
    /* padding: 3% 0; */
  }
  
  .search-container .MuiOutlinedInput-root {
    margin: 0 2%;
    width: 96%;
    padding: 0 2% !important;
  }
  
  .toggle-container {
    /* display: flex;
      margin: 2% 0 2% 25%; */
    margin-left: 2.5%;
    text-align: center !important;
  }
  
  .toggle-btn {
    background: #7b8bd4;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 0px !important;
    width: 18% !important;
    height: 5.4vh !important;
    margin-right: 5% !important;
    text-transform: none !important;
  }
  
  .toggle-container img {
    width: 20%;
    margin-right: 7%;
  }
  
  .service-card-container {
    margin-top: 5%;
    cursor: pointer;
  }
  
  .grid {
    margin: 0 0% 3% 2.5% !important;
    /* padding: 0 4% 4% 4% !important; */
    background: #f8f8f8;
    border-radius: 8px;
    text-align: center;
    transition: transform 1s;
    cursor: pointer;
  }
  
  .grid:hover {
    transform: scale(1.15);
  }
  
  .grid .flag {
    position: relative;
    text-align: center;
  }
  
  .grid .flag img {
    position: absolute;
    right: -27%;
    width: 50%;
  }
  
  .grid .flag p {
    position: absolute;
    right: -15%;
    text-align: center;
    font-family: "Avenir Next LT Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 0px;
    letter-spacing: 0.01em;
  
    color: #ffffff;
  }
  
  .car-bike-img-container-parent {
    display: flex;
    justify-content: center;
  }
  
  .car-bike-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  
  .car-bike-img-container img {
    width: 80%;
    height: auto;
  }
  
  .grid p {
    font-family: AvenirDemi;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    padding-bottom: 22%;
    color: #000000;
  }
  
  /* .car-bike-img-container:hover {
      scale: 1.5;
  } */
  
  @media screen and (max-width: 768px) {
    .toggle-container img {
      width: 20%;
      margin-right: 7%;
    }
  
    .service-card-container {
      margin-top: 5%;
    }
  
    .sub-heading {
      font-style: normal;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 14px;
      letter-spacing: 0.01em;
  
      color: #000000;
    }
  
    .toggle-container {
      display: flex;
      margin-left: 3%;
    }
  
    .toggle-btn {
      border-radius: 8px !important;
      width: 50% !important;
      margin: 0 5% 0 1% !important;
    }
  
    .grid {
      margin: 0 1.23% 3% 1.23% !important;
      padding: 0 2% 0% 2% !important;
      background: #f8f8f8;
      border-radius: 8px;
      text-align: center;
      transition: transform 1s;
    }
  
    .grid:hover {
      transform: scale(1);
    }
  
    .grid .flag img {
      position: absolute;
      right: -7%;
      width: 45%;
    }
  
    .grid .flag p {
      line-height: 0px;
      right: -3%;
      font-size: 9px;
    }
  
  
    .car-bike-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      width: 70px;
      overflow: hidden;
      margin: 0%;
  
    }
  
  
  
      .grid .car-bike-img-container img {
        width: 80%;
        height: auto;
      }
      
  
    
  
    .grid p {
      font-style: normal;
      font-weight: 600;
      font-size: 12px !important;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.01em;
  padding-bottom: 15%;
      color: #000000;
  
    }
  }
  