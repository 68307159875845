
.cart-mobile-container{
     border-radius: 6px; 
     padding: 2.5%;   
 }
 .cart-mobile-header h4{
   font-size: 5vw;
   font-family:'Avenir Next LT Pro';
   font-weight: 700 !important;
 }
 
 .cart-mobile-input-section{
      margin-top: 2.5%;
      background-color:#E5E5E5;
      padding: 2%;
      border-radius: 6px; 
 }
 
 .cart-mobile-input-section span{
      font-size: 3.5vw;
      font-weight: 600; 
      width: 20%;
 }
 
 .cart-mobile-input-section .cart-mobile-input-field{
      font-size: 3.5vw;
      font-weight: 500; 
      margin-left: 3%;
      background-color: transparent;
      border: none !important;
      outline: none !important;
      width: 80%;
 }
 
 .cart-mobile-container .cart-mobile-button{
      margin-top:5%;
      width: 100%;
      background-color: var(--blue);
      color: white;
      text-transform: capitalize;
      font-weight: 700;
      font-size: 4vw; 
 }
 
 .cart-mobile-container .cart-mobile-button:hover{
      background-color: var(--blue);
 }
 
 
 .cart-otp-section{
      margin-top: 5%;
      display:flex;
      justify-content: space-around;
 }
 .cart-otp-section input{
 background-color: #E5E5E5; 
 width: 40px !important;
 height:50px !important;
 border-radius: 8px;
 outline: none;
 border: none;
 }