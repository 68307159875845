.blog-card-container {
    display: inline-flex;
    width: 100%;
    overflow-x: scroll;
    margin-left: 2%;
}

/* .card-container {
    width: 35vw;
    padding-right: 5%;
} */

.card-img-container {
    width: 100%;
}

.card-img-container img {
    width: 100%;
    object-fit: contain;
}

.card-details-container {
    width: 100%;
}

.card-details-container h2 {
    font-family: AvenirDemi;
    font-style: normal;
    font-weight: 600;
    font-size: 26px !important;
    line-height: 31px;
    letter-spacing: 0.01em;

    color: #000000;
}

.card-details-container p {
    font-family: AvenirRegular;
    /* font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 133%;

    letter-spacing: 0.01em;

    color: #707070; */
    font-style: normal;
    font-weight: 400;
    font-size: 1vw !important;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

.card-details-container p .blog-link {
    text-decoration: none;
    color: #122164;
}

@media screen and (max-width: 768px) {
    .blog-card-container {
        margin-left: 4%;
    }

    /* .card-container {
        width: 90vw;
    } */

    .card-img-container img {
        filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15));
        border-radius: 8px;
    }

    .card-details-container h2 {
        font-family: AvenirDemi;
        font-style: normal;
        font-weight: 600;
        font-size: 16px !important;
        line-height: 19px;
        letter-spacing: 0.01em;

        color: #000000;
    }

    .card-details-container p {
        /* font-family: AvenirDemi;
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 133%;

        letter-spacing: 0.01em;

        color: #707070; */
        font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #000000;
    }
}