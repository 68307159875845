.view-detailed-bill-Modal {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0% 4%;
  max-height: 90%;
}

.view-detailed-bill-Modal .modal_body {
  width: 100%;
  border-radius: 2%;
  overflow: hidden;
  padding: 3%;
  background-color: #ffff;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0.4rem;
}

.service-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0.4rem;
}

.bill-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0.4rem;
}

.pay-later-bill-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0.4rem;
}

.header-wrapper .header-titles {
  font-size: 1.5rem !important;
  font-family: AvenirBold;
}

.header-wrapper .bill-title {
  font-size: 1.5rem !important;
  font-family: AvenirBold;
}

.price-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 27%;
}

.service-wrapper .service-fields {
  font-size: 1.1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: AvenirDemi;
}

.bill-wrapper .bill-fields {
  font-size: 1.1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: AvenirDemi;
}

.service-wrapper .price-wrapper .service-price-fields {
  font-size: 1.1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.service-wrapper .price-wrapper .service-price-fields span {
  font-family: AvenirRegular;
  color: #707070;
}

.bill-wrapper .price-wrapper .bill-price-fields {
  font-size: 1.1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.bill-wrapper .price-wrapper .bill-price-fields span {
  font-family: AvenirRegular;
  color: #707070;
}

.pay-later-bill-wrapper .bill-fields {
  font-size: 1.1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: AvenirBold;
  color: #122164;
}

.pay-later-bill-wrapper .price-wrapper .bill-price-fields {
  font-size: 1.1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.pay-later-bill-wrapper .price-wrapper .bill-price-fields span,
.pay-later-bill-wrapper
  .price-wrapper
  .bill-price-fields
  .footer-total-rupee-icon {
  font-family: AvenirDemi;
  color: #122164;
  font-size: 1.1rem !important;
}

.pay-later-bill-wrapper
  .price-wrapper
  .bill-price-fields
  .footer-total-rupee-icon {
  margin-right: 0.3rem !important;
}

.service-wrapper .footer-total-rupee-icon,
.bill-wrapper .footer-total-rupee-icon {
  font-size: 1.1rem !important;
  color: #707070;
  margin-right: 0.3rem !important;
}
