.check-circle {
  color: #122164;
}

.check-cancle-circle {
  color: grey;

}

.card-table-grid {
  padding: 5% 0 0 5% !important;
}




/* ------ card ---- */

.card {
  min-width: 20;
  font-size: 0.875rem !important;
  /* height: 50vh; */
}

.card-heading {
  font-size: 16px !important;
  height: 5ex !important;
  font-weight: 800 !important;
  font-family: AvenirBold !important;
}

.card-desc,
.card-desc li {
 font-family: AvenirRegular;
  font-size: 0.875rem !important;
}

.card-desc {
  padding-top: 2%;
  padding-bottom: 2%;
  height: 23ex;
  margin-bottom: 1.5 !important;
}

.card-desc ul {
  margin-left: 12%;
}

.card-price {
 font-family: AvenirDemi !important;
  color: #122164;
  font-weight: 700 !important;
}

.card-btn {
  width: 120px;
  height: 42px !important;
  background-color: rgb(123, 139, 212) !important;
  font-weight: 600 !important;
  margin-left: 50px !important;
  text-transform: none !important;
}

.table-head-cell {
  min-width: 270 !important;
  background-color: #F3F6F9 !important;
  /* font-weight: 800 !important; */
  font-size: 16px !important;
  font-family: AvenirDemi !important;
}

.table-body-cell{
 font-family: AvenirRegular !important;
}

.service-toggle-container {
  display: none !important;
}

@media screen and (max-width:768px) {
  .empty-container {
    display: none !important;
  }

  .card-heading {
    font-size: 12px !important;
    height: 2vh !important;
  }

  .card-desc {
    height: 13vh;
  }

  .card-desc,
  .card-desc li {
    font-size: 12px !important;
    font-family: 'Avenir Next LT Pro' !important;
  }

  .card-desc ul {
    margin-left: 12% !important;
    height: 25vh !important;
    overflow: hidden !important;
  }

  .table-head-cell {
    min-width: 60 !important;
    font-size: 12px !important;
  }

  .card {
    min-width: 15 !important;
    font-size: 0.875rem !important;
  }

  .card-btn {
    height: 4vh !important;
    width: 100%;
    background-color: rgb(123, 139, 212) !important;
    font-weight: 600 !important;
    margin-left: 0px !important;
  }

  /* service toggle buttons */

  .service-toggle-container {
    /* display: inline !important; */
    display: flex !important;
  }

  .service-toggle {
    /* color: gray ; */
    border: 1px solid #E7EBF0;
    text-align: center;
    color: black;
    margin-top: 20%;

  }

  .service-toggle-btn {
    width: 100%;
    border-radius: 0 !important;

    font-family: 'Avenir Next LT Pro';
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    /* or 16px */
    height: 4vh;
    text-align: center;

    color: #000000;

  }

}