* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
/*----------------------------SoS Service Page Css--------------------*/
.road-side-assistance-image-container {
  position: relative;
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
}
.road-side-assitance-textfield {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.road-side-assitance-textfield h4 {
  font-size: 30px;
  color: #fff;
  margin: 0;
  font-weight: 500;
  margin-bottom: 30px;
  z-index: 3;
  position: relative;
  font-family:'Avenir Next LT Pro';
  text-shadow: 0 1px 8px rgb(0 0 0 / 20%);
}
.road-side-assitance-image {
  background-image: url("../../../public/Assets/blog/blog.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 50vh;
  background-color: #122164;
}

.popular-service-info {
  align-items: center;
  justify-content: center;
}
.search-road-side-assitance {
  position: absolute;
  width: 100%;
  text-align: right !important;
}
.popular-service-content {
  text-align: center;
}
.search-btn {
  position: relative;
  justify-content: right !important;
  align-items: flex-end !important;
}
.near-me-search-bar {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  width: 100%;
  z-index: 4;
}

.near-me-search-btn {
  width: 134px !important;
  height: 40px !important;
  font-size: 16px !important;
  right: 10px !important;
  position: absolute !important;
  top: 14% !important;
  align-items: center !important;
  background-color: #122164 !important;
  color: white !important;
  border-radius: 4px !important;
  text-decoration: none !important;
  text-transform: none !important;
}
.input-field {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}
textarea:focus,
input:focus {
  outline: none;
}
*:focus {
  outline: none;
}
.rating-review {
  margin-top: 3% !important;
}
.vertical_line {
  width: 2px;
  height: 95%;
  background-color: #707070;
}
.sub-container {
  margin: 3%;
}

.people-booked-your-area {
  margin: 3%;
  text-align: center;
}
.popular-service-image {
  width: 100%;
  background-size: cover;
}



/* =====================Mobile View================== */
@media screen and (max-width: 768px) {
  .road-side-img {
    width: 100%;
    height: 100%;
  }
  .road-side-assitance-textfield {
    padding: 8% 2% 15% 2%;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 12%),
      0 2px 4px 0 rgb(0 0 0 / 14%);
    background-color: #fff;
    position: absolute;
    top: 100%;
    width: 90%;
    z-index: 10;
  }
  .road-side-assitance-textfield h4 {
    font-size: 15px;
    color: black;
    margin: 0;
    font-weight: 500;
    z-index: 3;
    position: relative; 
    text-shadow: 0 1px 8px rgb(0 0 0 / 20%);
  }
  .near-me-input-container {
    height: 54px !important;
  }
  .input-field {
    width: 100%;
    height: 8vh;
  }
  .near-me-search-btn {
    width: 100% !important;
    font-size: 16px !important;
    right: 0px !important;
    position: absolute !important;
    align-items: center !important;
    background-color: #122164 !important;
    color: white !important;
    border-radius: 4px !important;
    margin: 15% 0% !important;
    text-decoration: none !important;
    text-transform: none !important;
  }
  .near-me-search-bar {
    position: relative;
    display: block;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    z-index: 4;
    background-color: #fff;
    height: 10vh;
    margin-top: 5%;
  }
  .near-me-input-div {
    top: 10%;
  }
  .rating-review {
    margin-top: 45% !important;
  }
}
