.service-card-conatiner {
     padding: 2% 0% 2% 2%;
     border: 1px solid #E5E5E5;
     background: #FFFFFF;
     border-radius: 8px;
     margin-top: 16px;
}

.serviceCard-topSection {
     display: grid;
     grid-template-columns: 200px auto;
     gap: 2%;
     margin-bottom: 1%;
}

.serviceCard-topSection>.serviceCard-image {
     width: 100%;
}

.serviceCard-topSection>.serviceCard-image img {
     width: 100%;
}


.serviceCard-serviceNameandLists>.service-card-serviceName {
     /* font-size: 26px;
     font-weight: 600;
     font-family: AvenirDemi; */

     font-family: AvenirDemi;
     font-style: normal;
     font-weight: 600;
     font-size: 26px !important;
     line-height: 137.19%;
     /* identical to box height, or 36px */


     color: #121212;
     margin-top: -5px;
}

.serviceCard-commonServices {
     /* display: grid;
     grid-template-columns: 1fr 1fr; */
     display: grid;
     grid-template-columns: 40% 45%;
}

.details-container {
     margin-right: 2%;
}

.serviceCard-commonServices p {
     font-size: 18px;
     margin-top: 5px;
     font-family: AvenirRegular;
     display: flex;
}

.serviceCard-commonServices .commonMenu-checkIcon {
     display: none;
}

.serviceCard-commonServices .commonMenu-circleIcon {
     font-size: 13px !important;
     margin-top: 5px;
}

.serviceCard-ServiceActivities {
     display: grid;
     grid-template-columns: 1fr 1fr;
}

.serviceActivities-list {
     font-size: 18px !important;
     display: flex;
     margin-top: 10px;
     font-family: AvenirRegular;
}

.serviceActivities-list-checkicon {
     color: #51AE3A;
     margin-right: 2%;
}


.serviceCard-bottumSection {
     display: flex;
     justify-content: space-between;
     /* padding: 0% 2%; */
     padding-right: 2%;
     align-items: center;
}

.serviceCard-pricesSection {
     display: flex;
     align-items: center;
     background-color: white;
}

.serviceCard-pricesSection .serviceCard-displayPrice {
     font-size: 18px;
     font-weight: 500;
     color: #B1B1B1;
     position: relative;
     font-family: AvenirRegular;

}

.displayPrice-Rupee-icon {
     font-size: 15px !important;
}

.serviceCard-pricesSection .serviceCard-displayPrice::after {
     position: absolute;
     content: '';
     height: 1.3px;
     width: 110%;
     left: 0%;
     top: 30%;
     background-color: #b1b1b1;
}

.serviceCard-actualPrice {
     font-family: 'Avenir Next LT Pro';
     font-style: normal;
     font-weight: 700 !important;
     font-size: 26px !important;
     line-height: 137.19% !important;
     /* identical to box height, or 36px */


     color: #000000;

     display: flex;
     align-items: center;
}

.serviceCard-pricesSection .serviceCard-actualPrice {
     font-size: 30px;
     font-weight: 700;
     margin-left: 10px;
     font-family: AvenirBold;

}

.actualPrice-Rupee-icon {
     font-size: 18px !important;
}

.serviceCard-buttons {
     display: flex;
     justify-content: flex-end;
     align-items: center;
}

.serviceCard-moreButton {

     font-family: AvenirBold;
     font-style: normal;
     font-weight: 700 !important;
     font-size: 16px !important;
     line-height: 137.19%;
     /* identical to box height, or 22px */


     color: #122164;

     /* font-size: 16px !important;
     font-weight: 600 !important;
     color: #122164;
     cursor: pointer;
     font-family: AvenirDemi; */
     display: flex;
     align-items: center;
     justify-content: flex-end;
     width: 65%;
     padding: 2% 0;
}

.serviceCard-moreButton .add-remove-icon {
     font-size: 13px;
}


.select-vehicle-btn-container {
     /* margin-left: 80%; */
     display: flex;
     justify-content: flex-end;
     align-items: center;
     padding: 0 2%;
}

.serviceCard-addtoacartButton,
.select-vehicle-btn {
     font-size: 16px !important;
     font-weight: 700 !important;
     font-family: AvenirDemi;
     border-radius: 0px !important;
     border: 1px solid #707070 !important;
     margin-left: 15px !important;
     text-transform: none !important;
}

.select-vehicle-btn {
     transition: transform 1s !important;
}

.select-vehicle-btn:hover {
     background-color: #122164 !important;
     color: #FFFFFF !important;
     transform: scale(1.2);
}

.serviceCard-viewDetailsButton {
     display: none !important;
}

.price-section-call-btn {
     background: #7B8BD4;
     box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
     border-radius: 8px;
     width: 50%;
     padding: 2%;
     margin-right: 5%;
     color: #FFFFFF;
     text-align: center;
}

.price-section-call-btn a {
     display: flex;
     text-decoration: none;
     color: #FFFFFF;
     text-align: center;

}

.price-section-call-icon {
     margin-top: -3%;
     padding-top: 5%;
}



.service-card-conatiner .serviceCard-addtoacartButton, .select-vehicle-btn-container .select-vehicle-btn {
     /* border-radius: 8px !important; */
     color: #122164;
     width: 12vw;
     /* height: 100%; */
     padding: 5% 10% !important;
}



.service-card-image {
     width: 100%;
     /* height: 80%; */
     /* max-height:20px; */
     min-height: 150px;
     border-radius: 6px;
     overflow: hidden;
     position: relative;
     /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); */

}

.service-card-image img {
     width: 100%;
     height: 100%;
     position: absolute;

}

.service-card-price {
     display: flex;
     margin-top: 10px;
     justify-content: center;
     align-items: flex-start !important;
}

.service-card-price .Ex-price,
.Ex-price .rupee-icon {
     font-size: 1vw;
     font-weight: 400;
     color: #b1b1b1;
     position: relative;
}

.service-card-price .Ex-price .rupee-icon {
     font-size: 1vw;
     margin-bottom: -4% !important;
}

.service-card-price .Ex-price::after {
     position: absolute;
     left: 0%;
     top: 45%;
     content: ' ';
     width: 100%;
     height: 7%;
     background-color: #b1b1b1;
}

.service-card-price .new-price {
     font-size: 1.3vw;
     font-weight: 700;
     color: #000;
     margin-left: 5%;
}

.service-card-price .new-price .rupee-icon {
     font-size: 1.2vw;
     margin-bottom: -4%;
}


/* --------------------------------------------------------------------- */

.service-card-RightSection {
     padding-left: 2%;
}


.service-card-header h5 {
     margin-top: -1%;
     font-size: 26px;
     font-weight: 600;
}

.Comman-services {
     display: flex !important;
     flex-direction: row !important;
     margin-bottom: 2%;
}

.Comman-services p {
     font-size: 0.95vw;
     line-height: 21px;
     font-weight: 400;
}

.service-card-list {
     color: #000000;
     font-size: 18px;
     font-weight: 400;
     line-height: 16px;
     margin-top: 15px;
     display: flex;
     flex-direction: row;
     align-items: center;
}

.service-card-list-checkicon {
     font-size: 1.5vw !important;
     color: #51AE3A;
     ;
     margin-right: 2%;
}

.view-more-service {
     font-size: 16px !important;
     color: #122164;
     cursor: pointer;
     font-weight: 700 !important;
     margin-right: 2% !important;
     display: flex;
     align-items: center;
}

.service-card-bottum-buttons {
     margin-right: 10%;
     margin-top: 1%;
     display: flex;
     justify-content: flex-end;
}

.addtocart-btn {
     font-size: 16px;
     font-weight: bold !important;
     color: #122164;
     padding: 13px 31px;
     margin-left: 100%;
     border: 1px solid #707070 !important;
     border-radius: 6px !important;
}

.view-Cart {
     display: none !important;
     font-size: 12px !important;
     font-weight: 600 !important;
     color: #122164 !important;
     padding: 1% 2% !important;
     margin-left: 100%;
     border: 1px solid #707070 !important;
     border-radius: 6px !important;

}

@media screen and (max-width:768px) {
     .serviceCard-topSection {
          grid-template-columns: 140px auto;
          margin-bottom: 3%;
     }

     .serviceCard-topSection>.serviceCard-image img {
          border-radius: 8px;
     }

     .serviceCard-topSection>.serviceCard-serviceNameandLists {
          margin-left: 10%;
     }

     .serviceCard-serviceNameandLists>.service-card-serviceName {
          font-family: AvenirBold;
          font-style: normal;
          font-weight: 700;
          font-size: 16px !important;
          line-height: 137.19%;
          letter-spacing: 0.02em;
          color: #121212;
     }

     .serviceCard-commonServices {
          grid-template-columns: 1fr;
     }

     .serviceCard-commonServices p {
          font-size: 14px !important;
          margin-top: 8px;

          font-family: AvenirRegular;
          font-style: normal;
          font-weight: 400;
          color: #000000;

     }

     .serviceCard-commonServices .commonMenu-checkIcon {
          display: block;
          font-size: 20px !important;
          margin-top: 3px;
          margin-right: 5px;
          color: #51AE3A;
     }

     .serviceCard-commonServices .commonMenu-circleIcon {
          display: none;
     }

     .serviceCard-ServiceActivities {
          grid-template-columns: 1fr;
     }

     .serviceActivities-list {
          font-size: 14px !important;
          font-weight: 400 !important;
     }

     .serviceActivities-list-checkicon {
          font-size: 16px !important;
          margin-top: 3px;
          margin-right: 5px;
     }

     .serviceCard-moreButton {
          display: none;
     }

     .serviceCard-pricesSection .serviceCard-displayPrice {
          font-family: AvenirRegular;
          font-style: normal;
          font-weight: 400;
          font-size: 12px !important;
          line-height: 137.19%;
          /* or 16px */


          color: #B1B1B1;
     }

     .displayPrice-Rupee-icon {
          font-size: 10px !important;
     }

     .serviceCard-pricesSection .serviceCard-displayPrice::after {
          top: 40%;
          height: 1px;
     }

     .serviceCard-pricesSection .serviceCard-actualPrice {
          font-family: AvenirBold;
          font-style: normal;
          font-weight: 700;
          font-size: 18px !important;
          line-height: 137.19%;
          /* identical to box height, or 25px */


          color: #000000;
     }

     .actualPrice-Rupee-icon {
          font-size: 11px !important;
          margin-top: 1px !important;
     }

     .service-card-conatiner Button{
          padding: 0 ;
     }

     .serviceCard-addtoacartButton,
     .select-vehicle-btn {
          font-size: 3vw !important;
          font-weight: 600 !important;
          margin-left: 5px !important;
          padding: 2% 4% !important;
          flex: none;
     }

     /* .select-vehicle-btn-container{
          margin-left: 60%;
     } */

     .serviceCard-viewDetailsButton {
          /* font-size: 3vw !important;
          padding: 2% 4% !important;
          font-weight: 600 !important;
          font-family: AvenirDemi;
          border-radius: 0px !important;
          border: 1px solid #707070 !important;
          display: block !important;
          text-transform: none !important;
          flex: none; */

          display: block !important;
          border: none !important;
          font-family: AvenirDemi;
          font-style: normal !important;
          font-weight: 700 !important;
          font-size: 11px !important;
          line-height: 137.19% !important;
          /* identical to box height, or 22px */


          color: #122164 !important;
          text-transform: none !important;
          text-decoration: underline !important;
          width: 100% !important;
     }

     .select-vehicle-btn-container .select-vehicle-btn {
          border-radius: 8px !important;
          border: 0px solid !important;
          color: #FFFFFF !important;
          background-color: #7B8BD4 !important;
          width: 100% !important;
          height: 4vh;
          padding: 0 8px !important;
     }

     .serviceCard-addtoacartButton {
          border-radius: 8px !important;
          border: 0px solid !important;
          color: #FFFFFF ;
          background-color: #7B8BD4;
          width: 100% !important;
          height: 4vh;
          padding: 0 8px !important;
     }

     .price-section-call-btn {
          width: 68%;
     }


     .service-card-conatiner .serviceCard-addtoacartButton {
          border-radius: 8px !important;
          color: #122164;
          width: 25vw !important;
          /* height: 100%; */
          padding: 3% 0% !important;
     }


     .service-card-conatiner {
          grid-template-columns: minmax(30%, 35%) 65%;
     }

     .service-card-header h5 {
          font-size: 16px;
     }

     .Comman-services {
          display: none !important;
     }

     /* view Button hidde in mobile view */
     .view-more-service {
          display: none;
     }

     .service-card-image {
          background-color: red;
          width: 100% !important;
          min-height: 150px;
          /* min-height: 80px; */
     }

     .service-card-image img {
          width: 100% !important;
          height: 100%;
     }

     .service-card-price .Ex-price,
     .Ex-price .rupee-icon {
          font-size: 3vw;
     }

     .service-card-price .Ex-price .rupee-icon {
          font-size: 3vw !important;
     }

     .service-card-price .new-price,
     .new-price .rupee-icon {
          font-size: 3.2vw;
     }

     .service-card-price .new-price .rupee-icon {
          font-size: 3.2vw;
     }

     .service-card-list {
          margin: top 2% !important;
          font-size: 3.5vw;
          font-weight: 400;
          margin-bottom: 0% !important;
     }

     .service-card-list-checkicon {
          font-size: 17px !important;
     }

     .service-card-bottum-buttons {
          margin: 1.5vw 0% 0% 0%;
          display: flex;
          justify-content: flex-start;
     }

     .view-Cart {
          display: block !important;
     }

     .addtocart-btn {
          font-size: 12px !important;
          font-weight: 400 !important;
          padding: 1% 2% !important;
          margin-left: 10px !important;
     }
}

/* -------------------------iphone-------------------- */

@media screen and (max-width:330px) {
     .service-card-conatiner {
          grid-template-columns: 40% 60%;
     }

     .service-card-header h5 {
          font-size: 14px;
     }

     .service-card-list {
          font-size: 11px;
          font-weight: 400;
     }

     .service-card-list-checkicon {
          font-size: 15px !important;
     }

     .view-Cart {
          display: block !important;
          font-size: 11px !important;
     }

     .addtocart-btn {
          font-size: 11px !important;
          font-weight: 400 !important;
          padding: 1% 2% !important;
     }
}