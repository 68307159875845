* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*-----------------Order history Container-----------*/
.order-history-container {
  margin: 5%;
}

.order-history-container h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: justify;
}
/*------------------order status section----------------*/
.order-status {
  margin: 5% 2%;
}
.order-status h3 {
  margin-bottom: 2%;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: justify;
}
/* -----------divider-------- */
.divider {
  border: 1px solid #707070 !important;
}
/*------------ongoing order-------------*/
.on-going-order {
  padding: 2%;
  margin-bottom: 3%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  /* height: 450px; */
}
/*------------vehicle iamge and name section-----------*/
.vehicle-image-and-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
/*------vehicle image----------*/
.vehicle-image {
  margin: 2%;
}
/*-----------vehicle name----------*/
.vehicle-name {
  text-align: justify;
  margin: 2% 5%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #121212;
}
/*---------booking date-------*/
.booking-date {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #707070;
}
/*------------------booking id and status------------*/
.bookingid-status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/*-----------------booking id-------------*/
.booking-id {
  margin: 2%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #707070;
}
/*--------------booking number---------------*/
.booking-number {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #121212;
  text-align: justify;
}
/*-----------status-----------*/
.status {
  text-align: justify;
  margin: 2% 5%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #707070;
}
/*------------status name--------*/
.status-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #121212;
  text-align: justify;
}
/* -----------------datetime and viewdeatails section----------- */
.datetime-viewdetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/*-------slot date and time---------*/
.slot-date-time {
  margin: 2%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #707070;
  text-align: justify;
}
/*----------date and time--------*/
.date-time {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #121212;
  text-align: justify;
}
/*----------view details---------*/
.view-details {
  text-align: justify;
  margin: 2% 5%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #000000;
}
/*----------track button----------*/
.track-btn {
  width: 100% !important;
  background-color: #122164 !important;
  color: #ffffff !important;
  border-radius: 4px;
  text-decoration: none !important;
  text-transform: none !important;
}

/*-------------complete order-----------*/
.complete-order {
  padding: 2%;
  margin-bottom: 3%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  /* height: 250px; */
}
/*-------------order again buttton----------*/
.order-again-btn {
  box-sizing: border-box !important;
  width: 100% !important;
  border: 1px solid #707070;
  border-radius: 4px;
  text-decoration: none !important;
  text-transform: none !important;
  color: #000000 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #000000;
}
/*---------======--------Order History Mobile view------===========-*/
@media screen and (max-width: 768px) {
  .order-history-container {
    margin: 3%;
  }

  .track-btn {
    height: 3.5vh;
    font-size: 16px !important;
  }
}
