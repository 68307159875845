* {
    margin: 0;
    padding: 0;
}

.logo-img-container {
    margin-left: 3%;
    width: 97%;
    height: 50%;
}

.logo-img-container img {
    width: 80%;
}



.img-sub-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 20%;
    height: 50%;
    width: 100%;
}


@media screen and (max-width:768px) {

    .logo-img-container {
        margin-left: 4%;
        width: 92%;
        height: 50%;
    }
}