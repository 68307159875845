.empty-cart {
     text-align: center;
     padding-top: 1%;
}




.addtocart-box {
     border-bottom: 1px solid gray;
     padding: 4% 2% 4% 0% !important;
     max-height: 35%;
     overflow: auto;

}

.addtocart-box h6 {
     font-family: AvenirDemi;
     color: #707070;
     font-size: 1vw !important;
}

.addtocart-servicePrice h6 {
     font-family: AvenirDemi;
     color: black;
}

.cancel-icon {
     font-size: 1.3vw !important;
     color: #707070;
     margin-left: 5%;
     transition: 0.5s;
}

.cancel-icon:active {
     scale: 1.1;
     color: red;
}



.total,
.paynow-section,
.paylater {
     font-size: 21px !important;
     font-weight: bolder !important;
     color: #707070;
}

.paynow-section {
     padding: 20px 0px;
}

.paynow-section .paynow-rupee {
     position: relative;
}

.paynow-section .paynow-rupee::after {
     content: '';
     position: absolute;
     width: 150%;
     height: 1px;
     right: 0%;
     bottom: -50%;
     background-color: gray;
     /* margin-top: 5%; */
}



.addtocart-checkoutbutton-link {
     text-decoration: none;
}

.addtocart-cheackoutButton {
     background-color: #122164 !important;
     font-size: 26px !important;
     font-weight: 700 !important;
     color: #FFF !important;
     border-radius: 0% !important;
     text-transform: capitalize !important;
}

@media screen and (max-width:1440px) and (min-width:768px) {
     .empty-cart img {
          width: 100%;
     }
}