* {
  margin: 0px ;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------order status container-------------*/
.customer-details {
  margin-top: 8%;
}

.order-status-container {
  background: #F8F8F8;
  padding: 5%;
}

.ongoing-order-status {
  box-sizing: border-box;
  width: 95%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 3%;
}

.heading-ongoing {
  color: #122164;
  font-size: 12px;
  font-weight: 800;
  float: left;
}

.order-number {
  float: left;
  font-size: 12px;
  font-weight: 500;
}

.refer-faq {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  border: 1px solid #e5e5e5;
}

.order-status-map {
  margin: 5% 0%;
  width: 100%;
  height: 250px;
}

.vehicle-details-container {
  align-items: flex-start;
  text-align: left;
}

.vehicle-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5%;
  margin-top: 5%;
}

.vehicle-details-logo {
  width: 15%;
}

.details {
  width: 40%;
}

.customer-name-working-order {
  text-align: justify;
}

.customer-name {
  font-family:'Avenir Next LT Pro';
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  /* identical to box height */

  letter-spacing: 0.01em !important;

  color: #5BAA41;
}

.images-order {
  align-items: flex-start;
  text-align: justify;
}

.view-details {
  width: 45%;
  /* text-align: ; */
  color: red !important;
  display: block;
  justify-content: center;
  align-items: center;
  /* margin-top: 190% !important; */
}

.heading-orderstatus {
  margin-bottom: 5%;
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #122164;
}

.job-card-download {
  text-align: center;
  margin-top: 70%;
  background: #7b8bd4;
  border-radius: 4px;
  color: red;
}

.download-icon {
  padding: 3% 3% 0 0;
  color: #ffffff;
}

.refer-faq-container {
  background: #F8F8F8;
  border: 1px solid #E5E5E5;
  padding: 1%;
}


.refer-earn {
  /* background-color: #212164; */
  height: 50%;
  width: 92%;
  /* position: relative; */
  margin: 4%;
}

.refer-earn img{
  width: 100%;
  height: 100%;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active, .css-4ff9q7.Mui-completed, .css-4ff9q7.Mui-active, .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  color: #5BAA41 !important;
}




/* ==================mobile view started========= */

@media screen and (max-width: 768px) {
  .customer-details {
    margin: 8% 0;
    
  }

  .vehicle-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5%;
    margin-top: 5%;
  }

  .order-status-container {
    margin: 3%;
  }

  .ongoing-order-status {
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 2%;
    border: none !important;
    margin: 0 !important;
  }

  .order-status-map {
    margin: 5% 0%;

  }
}