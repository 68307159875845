.garageRegistrationForm-container {
  width: 100%;
  /* overflow: hidden; */
}
.garage-top-content {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 80vh;
}

.garage-hero {
  width: 100%;
}

.garage-top-content-left {
  position: absolute;
  top: 40%;
  left: 1%;
}
.garage-top-content-left h4 {
  font-size: 4vw;
  font-family: 'Avenir Next LT Pro';
  color: white;
}

.garage-top-content-right {
  position: absolute;
  right: 2%;
  bottom: 1%;
}
.garage-top-content-right h6 {
  font-family: 'Avenir Next LT Pro';
  font-size: 20px;
  color: white;
  font-weight: 800;
}
.garage-registrationForm-container {
  /* margin: 0px 1%; */
  padding: 25px 0px;
  position: relative;
}

.garage-registrationForm-container::after {
  content: "";
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  background-image: url(https://gativanweb.s3.amazonaws.com/Logomark+Main+Dark+Mode.png);
  background-size: 50px 50px;
  opacity: 0.1;
}
.garage-registrationForm-top-content {
  margin-left: 1%;
  font-size: 25px;
  font-family: 'Avenir Next LT Pro';
  text-transform: capitalize;
  /* color: white; */
}

.garage-registrationForm {
  width: 50%;
  padding: 20px 10%;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 50%;
  left: 25%;
  border-radius: 6px;
  background-color: white;
  z-index: 1;
  box-shadow: rgb(7, 7, 7) 1px 1px 5.555px -1px;
}

.garage-registrationFrom-header h4 {
  font-family: 'Avenir Next LT Pro';
}

.garage-registrationForm-form {
  width: 100%;
}
.garage-registrationForm-fields {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  font-size: 16px;
  color: gray;
  padding: 10px 5px;
  font-family: 'Avenir Next LT Pro';
  border: none;
  background-color: rgb(225, 226, 227);
  border-radius: 6px;
}

.garage-registrationForm-fields-Eyear {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  font-family: 'Avenir Next LT Pro';
  color: gray;
  border: none;
  background-color: rgb(225, 226, 227);
  border-radius: 6px;
}

.garage-registrationForm-fields-Eyear label {
  width: 40%;
}

.garage-registrationForm-fields-Eyear input {
  /* width: 50%; */
  background-color: rgb(225, 226, 227);
  border: none;
  border-bottom: 1px solid gray;
}

.garage-registrationForm-submitBtn {
  margin: 60px 0px;
  display: grid;
  place-items: center;
  outline: none;
  border: none;
  border-radius: 0%;
  background-color: #4f6df5d5;
  color: white;
  font-family: 'Avenir Next LT Pro';
}

@media screen and (max-width: 768px) {
  .garageRegistrationForm-container {
    /* margin: 2px 0px; */
    margin: 0%;
    padding: 0px;
  }
  .garage-top-content {
    /* padding: 5px 8px; */
    height: 50vh;
  }

  .garage-top-content-left {
    top: 39%;
  }
  .garage-top-content-left h4 {
    font-size: 7vw;
  }

  .garage-registrationForm-container {
    padding-top: 10px;
  }
  .garage-registrationForm {
    margin-top: 15px;
    width: 90%;
    display: flex;
    left: 5%;
    box-shadow: rgb(7, 7, 7) 1px 1px 3px;
  }

  .garage-top-content-right h6 {
    font-size: 12px;
  }
  .garage-registrationFrom-header h4 {
    font-size: 12px;
  }
  .garage-registrationForm-top-content {
    font-size: 13px;
    margin-left: 3%;
  }

  .garage-registrationForm-fields,
  .garage-registrationForm-fields-Eyear {
    margin: 10px 0px;
    font-size: 12px;
    height: 30px;
    padding: 7px 5px; 
  }

  .garage-registrationForm-fields-Eyear label {
    width: 60%;
  }
  
  .garage-registrationForm-submitBtn{
    margin-top: 25px;
  }
}
