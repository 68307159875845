.faq-accordion {
    margin-left: 2.5%;
    box-shadow: none !important;
    margin-top: 1%;
}

.faq-accordion-header {
    background-color: #f8f8f8 !important;
    height: 84px;
}

.faq-accordion-header h2 {
   font-family: AvenirDemi;
    font-size: 26px;
    font-weight: 600;
}

.faq-accordion-body p {
   font-family: AvenirRegular;
    font-size: 1.5vw;
    background-color: #FCFCFC;
    border: none !important;
}

@media screen and (max-width:768px) {
    .faq-accordion-header h2 {
        font-size: 16px;
        font-weight: 500;
    }

    .faq-accordion-body p {
        font-size: 14px;
        font-weight: 400;
    }
}

/* media query for laptop and tablet */
@media screen and (max-width:1440px) and (min-width:768px) {
    .faq-accordion-header {
        height: 70px !important;
    }

    .faq-accordion-header h2 {
        font-size: 20px !important;
    }
}