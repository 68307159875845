* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
/*==============gativan image container-================*/
.img-container {
  position: relative;
  text-align: center;
  color: white;
}
/*---------login background image----------*/
.login-background {
  width: 100%;
}
/*------------back to booking--------*/
.back-to-booking {
  position: absolute;
  top: 8px;
  left: 16px;
}
/*-----------gativan logo------------------*/
.gativan-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}
/*----------social media logo-----------*/
.social-media-logo {
  position: absolute;
  bottom: 10%;
  left: 16px;
  padding: 2%;
  display: flex;
  justify-content: space-between;
}
/*-----------facebook logo----------*/
.facebook-logo {
  width: 2%;
  height: 2%;
}
/*---------twitter logo-----------*/
.twitter-logo {
  margin-left: 8%;
  width: 2%;
  height: 2%;
}
/*----------instagram logo------------*/
.instagram-logo {
  margin-left: 8%;
  width: 2%;
  height: 2%;
}

/*------------Text field signup section-------*/
.personal-info {
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 25%;
}
.signup-form {
  margin: 5%;
  align-items: center;
  justify-content: center;
  display: block;
}
.signup-form h4 {
  text-align: left;
  font-size: 35px;
}
.signup-form h5  {
  text-align: left;
  font-size: 20px;
  
}

/*-----------signup text name-------*/
.signup-name {
  text-align: left;
  margin-top: 5% !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #707070;
}
/*------------Enter input field-------------*/
.enter-signup-info{
    width: 100%;
    background: #e5e5e5;
    border-radius: 4px;
    margin-top: 3% !important;
  }
/*----------sign up button section--------*/
.sign-up-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}
/*---------sign up button-------*/
.signup-btn {
  background-color: #122164 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  letter-spacing: 0.02em !important;
  color: white !important;
  text-transform: none !important;
  margin-top: 2%;
}
/*=======================Mobile View Started OTP Section=================*/
@media screen and (max-width: 768px) {
    .social-media-logo {
        position: absolute;
        bottom: 8px;
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        left: 45%;
        transform: translate(-50%, -50%);
      }
      
      .personal-info{
        margin-top: 3%;
      }
}
