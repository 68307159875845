/* Lead Card styling */
.share-lead-card-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 0.5rem;
}

.share-lead-card-container .error {
  font-size: 2rem;
  font-family: AvenirDemi;
  color: #d0342c;
}

.share-lead-card-container .share-lead-container {
  background-color: #122164;
  padding: 1rem 2rem;
  /* min-height: 92% !important;
    height: 100% !important; */
  width: 50% !important;
  /* position: absolute; */
  /* overflow: auto !important; */
}

.share-lead-card-container .card-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  /* height: 10%; */
}

.share-lead-card-container .card-header-section .heading {
  color: #fff;
  font-size: 2.1rem !important;
  font-family: AvenirBold !important;
  margin: 5px 0px !important;
}

.share-lead-card-container .card-header-section .logo {
  width: 170px !important;
  /* height: 100%; */
}

.share-lead-card-container .card-header-section .logo img {
  width: 100% !important;
  /* height: 100%; */
}

.share-lead-card-container .user-section {
  padding: 1rem !important;
  background-color: #fff;
}

.share-lead-card-container .user-section .grid-vehicle-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-lead-card-container .user-section .grid-vehicle-col img {
  max-height: 190px;
  max-width: 100%;
  min-height: 100px;
  width: 300px;
}

.share-lead-card-container .grid-user-col .user-name {
  font-size: 1rem;
  color: #121212;
  font-family: AvenirDemi !important;
}
.share-lead-card-container .grid-user-col .user-value {
  font-size: 1rem;
  color: #6a7086;
  font-family: AvenirRegular !important;
  margin: 2px 0;
}
.share-lead-card-container .grid-user-col .user-label {
  font-size: 1rem;
  font-family: AvenirDemi !important;
  color: #121212;
  margin: 2px 0;
}

.share-lead-card-container .sub-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 0 1rem;
}
.share-lead-card-container .sub-header-section .sub-heading {
  font-size: 1.6rem !important;
  color: #fff !important;
  font-family: AvenirDemi !important;
}
.share-lead-card-container .sub-header-section .sub-logo {
  width: 30px;
  height: 100%;
}

.share-lead-card-container .sub-header-section .sub-logo img {
  width: 100%;
}

.share-lead-card-container .services-section {
  padding: 1rem;
  background-color: #fff;
  min-height: 60%;
  /* overflow: auto; */
  /* position: relative; */
}

.share-lead-card-container .services-section::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.share-lead-card-container .services-list .service-title {
  font-size: 20px;
  color: #122164;
  font-family: AvenirBold;
}
.share-lead-card-container .services-list .activity-wrapper {
  margin-top: 0.5rem !important;
}

.share-lead-card-container .services-list .activity-wrapper .activities-box {
  display: flex;
  justify-content: space-between;
}

.share-lead-card-container
  .services-list
  .activity-wrapper
  .activities-box
  .activities {
  margin: 0.2rem 0;
  font-family: AvenirRegular !important;
  color: #6a7086 !important;
}

.share-lead-card-container
  .services-list
  .activity-wrapper
  .activities-box
  .activities
  .sr-no {
  font-size: 1rem;
  font-family: AvenirBold !important;
  color: #000 !important;
}

.share-lead-card-container .divider {
  height: 2px;
  margin-top: 0.2rem !important;
  background-color: #000 !important;
  width: 100px;
}

.share-lead-card-container .total-divider {
  height: 2px;
  background-color: #121212;
}

.share-lead-card-container .services-list .check-icon {
  color: #51ae3a;
}

.share-lead-card-container .services-list::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.share-lead-card-container .sub-total-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.7rem 0;
}

.share-lead-card-container .sub-total-wrapper .sub-total-heading {
  font-size: 1rem;
  font-family: AvenirBold !important;
  color: #000;
}

.share-lead-card-container .sub-total-wrapper .sub-total-amount {
  font-size: 1.6rem;
  font-family: AvenirRegular !important;
  display: flex;
  align-items: center;
  color: #6a7086;
}

.share-lead-card-container .sub-total-wrapper .sub-total-amount .rupee-icon {
  font-size: 1.6rem !important;
  color: #000;
}

.share-lead-card-container .total-container {
  background-color: #fff;
  padding: 0.2rem 0;
}
.share-lead-card-container .total-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.8rem 0;
  padding: 0 0.5rem;
}

.share-lead-card-container .total-wrapper .total-heading {
  font-size: 1.6rem;
  font-family: AvenirBold !important;
  color: #122164 !important;
}

.share-lead-card-container .total-wrapper .total-amount {
  font-size: 1.6rem;
  font-family: AvenirRegular !important;
  display: flex;
  color: #6a7086;
  align-items: center;
}

.share-lead-card-container .total-wrapper .total-amount .rupee-icon {
  font-family: AvenirBold !important;
  font-size: 1.6rem !important;
  color: #000 !important;
}

.share-lead-card-container .back-to-view-card-link {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.share-lead-card-container .share-lead-card-container {
  margin: 1rem 0px;
}

.share-lead-card-container .share-lead-card-container .share-lead-card-button {
  background-color: #122164;
  color: #fff;
  padding: 10px;
  font-family: AvenirBold;
}

@media screen and (max-width: 1024px) {
  .share-lead-card-container .share-lead-container {
    padding: 1rem 1rem;
  }
  .share-lead-card-container .card-header-section .heading {
    font-size: 1.4rem !important;
  }
  .share-lead-card-container .card-header-section .logo {
    width: 120px;
  }

  .share-lead-card-container .card-header-section .logo img {
    width: 100%;
  }
}

/* LAPTOP DEVICE STYLE */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .share-lead-card-container .services-container {
    height: 76% !important;
  }
  .share-lead-card-container .share-lead-container {
    width: 75% !important;
  }
}

/* TABLET  DEVICE STYLE */

@media screen and (min-width: 425px) and (max-width: 768px) {
  .share-lead-card-container .services-container {
    height: 76% !important;
    .share-lead-card-container .share-lead-container {
      width: 95% !important;
    }
  }
  .share-lead-card-container .services-section {
    min-height: 35%;
  }

  .share-lead-card-container .services-list .service-title {
    font-size: 18px;
  }
}

/* MOBILE  DEVICE STYLE */

@media screen and (max-width: 425px) {
  .share-lead-card-container .services-container {
    height: 76% !important;
  }
  .share-lead-card-container .services-section {
    min-height: 20%;
  }
  .share-lead-card-container .share-lead-container {
    width: 100% !important;
  }
}

/* SMALL DEVICES */

@media only screen and (max-width: 320px) {
  .share-lead-card-container .services-container {
    height: 75% !important;
  }
  .share-lead-card-container .services-list .service-title {
    font-size: 16px;
  }
  .share-lead-card-container .share-lead-container {
    width: 100% !important;
  }
}

/* LAPTOPS DEVICES */

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .share-lead-card-container .services-section {
    min-height: 44%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .share-lead-card-container .services-container {
    height: 77% !important;
  }
  /* .services-section {
        min-height: 35%;
      } */
}

/* LARGE DEVICES */

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .share-lead-card-container .services-section {
    min-height: 44%;
  }
  /* .services-container {
        height: 78% !important;
      } */
}

/* EXTRA LARGE DEVICES */

@media screen and (min-width: 1500px) {
  .share-lead-card-container .services-container {
    height: 80% !important;
  }
  .share-lead-card-container .services-section {
    min-height: 48%;
  }
}
