.change-vehicle-card {
    width: 100%;
    height: 613px;
    background-color: #fff;
    box-shadow: 0px 1px 44px rgba(120, 120, 120, 0.1);
    overflow: hidden;
    padding: 2%;
    position: sticky;
    top: 8%;
}

.changeVehicle-cantainer {
    background-color: #FFF;
    padding-bottom: 2%;
    height: 25%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border-bottom: 1px solid #9C9C9C;
}

.changeVehicle-modelName {
    grid-column: 1/2;
    grid-row: 1/2;
    display: grid;
    align-items: center;
}

.brandAndModelName {
    font-family: AvenirBold;
    font-size: 1.3vw !important;
    font-weight: 700 !important;
    color: black;
}

.modelVariant {
    font-size: 1vw !important;
    margin-top: 1%;


    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 137.19%;
    /* identical to box height, or 22px */


    color: #9C9C9C;
}

.ChangeVehicle-btn {
    grid-column: 1/2;
    grid-row: 2/3;
    display: grid;
    align-items: center;
}

.ChangeVehicle-btn-button {
    font-size: 1vw;
    font-weight: 400;
    color: red;
    cursor: pointer;
    font-family: 'Avenir Next LT Pro';
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.ChangeVehicle-btn-button-pencil {
    font-size: 20px !important;
}

.ChangeVehicle-btn-button h4 {
    margin-top: 2%;

    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 137.19%;
    /* or 19px */


    color: #FF0000;
}




.changeVehicle-modelImage {
    grid-column: 2/-1;
    grid-row: 1/3;
    display: grid;
    /* background-color: black; */
    position: relative;

}

.changeVehicle-modelImage-Image {
    position: relative;
    display: grid;
    align-items: flex-end;
    height: 100% !important;
    min-width: 123px;
    max-width: 200px;

}

.changeVehicle-modelImage-Image img {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* ------------------------------------------ */

.cart-image {
    width: 100%;
    height: 75%;
    margin-top: 3%;
    display: grid;
    justify-content: center;
    position: relative;
}

.cart-image img {
    position: absolute;
    width: 100%;
    height: 100%;
}

@media screen and (min-Width:1200px) {
    /* .changeVehicle-modelImage-Image{
        min-width: 153px !important;
        max-width: 200px !important;
        height: 173px;
    } */
}

@media screen and (max-width:768px) {

    .change-vehicle-card {
        height: 80%;
        box-shadow: none;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        padding: 0 2%;
    }

    .change-vehicle-card img {
        width: 100%;
        height: 100%;
    }

    .changeVehicle-cantainer {
        grid-template-columns: 1.5fr 2fr 1.5fr;
        grid-template-rows: 1fr;
        height: 100%;
        gap: 1%;
        border: none;
    }

    .changeVehicle-modelName {
        grid-column: 2/3;
        grid-row: 1/2;
        display: grid;
        justify-content: flex-start;
        align-items: center;

    }

    .changeVehicle-modelName .brandAndModelName {
        font-size: 4.7vw !important;

        font-family: AvenirBold;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 137.19%;
        /* identical to box height, or 22px */

        text-align: start;
        letter-spacing: 0.02em;

        color: #121212;
    }

    .changeVehicle-modelName .modelVariant {
        font-family: AvenirRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 137.19%;
        /* or 16px */


        color: #000000;
    }

    .ChangeVehicle-btn {
        grid-column: 3/4;
        grid-row: 1/2;
        display: grid;
        justify-content: flex-end;
        align-items: center;
        margin-right: 8%;
    }

    .ChangeVehicle-btn-button {
        font-size: 9px;
        font-weight: 600;
        color: #122164;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Avenir Next LT Pro';
    }

    .ChangeVehicle-btn-button-pencil {
        font-size: 25px !important;
        color: #122164 !important;
    }

    .ChangeVehicle-btn-button h4 {
        font-family: AvenirDemi;
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 137.19%;
        /* or 12px */

        letter-spacing: 0.01em;

        color: #122164;
    }

    .changeVehicle-modelImage {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .ChangeVehicle-btn-button h4 {
        margin-top: 7%;
    }


}