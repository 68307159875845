* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.home-container::-webkit-scrollbar {
  display: none;
}

/* ------------------------------------------------------------- */
.home-container {
  display: grid;
  grid-template-columns: 65% auto;
  grid-template-rows: 75vh auto;
}

/* -------------------home page image---------------------------------- */
.home-image {
  background-color: #212164;
  grid-column: 1/-1;
  grid-row: 1/2;
  height: 100%;
  width: 100%;
}

.home-background-logo {
  position: relative;
  width: 100%;
  height: 100%;
}

.home-background-logo img {
  width: 100%;
  height: 100%;
}

/* *******Carousel *************** */

.home-desktop-carousel {
  grid-column: 1/-1;
  grid-row: 1/2;
  height: 100%;
  width: 100%;
}

.home-carousel {
  margin-top: 2%;
  margin-bottom: 2%;
  display: none;
  width: 100%;
  height: 100%;
}

.home-carousel-item {
  padding: 0 2%;
}

.home-carousel-item img {
  /* height: 100%; */
  width: 100%;
  /* padding: 0 1%; */
}

/* -----------------------------cart----------------------------- */
.home-cart {
  /* background-color: rgba(238, 77, 3, 0.678); */
  grid-column: 2/-1;
  grid-row: 1/3;
  margin-right: 7%;
  margin-left: 2%;
  z-index: 1;
  position: relative;
  padding-left: 5%;
  height: auto;
}

.cart-card {
  width: 100%;
  background-color: white;
  /* height:11%;  */
  padding: 4% 3%;
  position: sticky;
  top: 12%;
  box-shadow: 0px 0px 5px;
  overflow: auto;
}

/* ---------------------main-contain------------------------------------- */
.home-mainconetin {
  grid-column: 1/2;
  grid-row: 2/3;
  margin-left: 5%;
  position: relative;
  /* background-color: orangered; */
  overflow: hidden;
}

.homepage-headers h2 {
  font-size: 1.7vw !important;
  font-family: AvenirBold;
  font-weight: bold;
  margin-left: 24px;
  margin-top: 32px;
  margin-bottom: 10px;
  position: relative !important;
}

.homepage-headers h2::before {
  content: "";
  position: absolute !important;
  top: 25% !important;
  left: -1.6vw !important;
  width: 1vw !important;
  height: 1.5vw !important;
  background-color: #212164;
}

.sub-heading {
  margin-left: 3%;
}

.home-our-services {
  width: 100%;
}

.home-gativan-video-section {
  width: 100%;
  margin-bottom: 5%;
}

.home-gativan-experts {
  width: 100%;
  margin-bottom: 5%;
}

.home-refer-earn {
  background-color: #212164;
  height: 244px;
  width: 100%;
  margin-right: 41px;
  position: relative;
}

.home-refer-image,
.home-refer-image img {
  width: 100%;
  height: 100%;
}

.refer-div {
  position: absolute;
  top: 25%;
  margin-left: 75px;
  width: 400px;
}

.refer-div h4 {
  color: white;
  font-weight: bold;
  font-size: 45px;
  position: relative;
}

.refer-div h4::after {
  position: absolute;
  content: "";
  width: 55%;
  height: 5px;
  background-color: white;
  left: 0%;
  top: 60px;
}

.refer-div p {
  margin-top: 15px;
  color: white;
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
}

.refer-div p font {
  font-weight: bold;
}

.home-how-gativan-work {
  width: 100%;
}

.home-gativan-motion-performance {
  background-color: white;
}

/************************Service Price Table******************/
.home-vsprice {
  position: relative;
}

.home-vsprice {
  position: relative;
}

.VSprice-btns {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.VSprice-btns Button {
  margin-left: 12px;
  padding: 15px 68px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.Serviceprice_table {
  margin-bottom: 100px;
  margin-left: 0px;
  color: green;
  width: 100% !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Sexampleerviceprice_table ::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for IE, Edge and Firefox */
.Serviceprice_table {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.Serviceprice_Heading {
  background-color: #212164;
  color: #fff;
}

.Serviceprice_tabledata {
  background-color: #f8f8f8;
  border: 0.5px solid #707070;
  box-sizing: border-box;
}

/* --------------------------What our customers say about us?----------------- */
.home-woc-say {
  width: 100%;
}

.home-blog-post {
  background-color: gray;
  height: 250px;
}

/* ======================= Mobile =============================================== */

@media screen and (max-width: 768px) {
  .home-container {
    grid-template-columns: 100%;
    grid-template-rows: 200px auto;
  }

  .home-desktop-carousel {
    display: none !important;
  }

  .sub-heading {
    margin-left: 7%;
  }

  /* --------------------------------------------- */
  .home-image {
    background-color: white;
    height: 200px;
    width: 100% !important;
  }

  .home-background-logo {
    display: none;
  }

  .home-carousel {
    display: block;
  }

  /* --------------------------------- */

  .home-cart {
    grid-column: 1/2;
    grid-row: 2/3;
    margin: 0%;
    margin-top: 20px;
    padding: 0px;
    display: flex;
    justify-content: center;
    background-color: #212164;
    z-index: 0;
  }

  .cart-card {
    position: static;
    width: 100%;
    height: auto;
    box-shadow: none;
    overflow: hidden;
  }

  /* ----------------------------------- */
  .homepage-headers h2 {
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    font-size: 20px !important;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #000000;
    /* margin-left: 15px !important; */
  }

  .homepage-headers h2::before {
    width: 2vw !important;
    height: 4vw !important;
    top: 1vw !important;
    left: -4vw !important;
  }

  .home-mainconetin {
    grid-column: 1/2;
    grid-row: 3/4;
    margin: 0px;
    padding: 0% 2%;
    position: relative;
  }

  .home-refer-earn {
    margin: 0px;
    position: absolute;
    width: 96%;
    height: 86px;
    top: 100%;
    border-radius: 15px;
    overflow: hidden;
  }

  .refer-div {
    width: 200px;
    top: 10px;
  }

  .refer-div h4 {
    font-size: 15px;
  }

  .refer-div h4::after {
    height: 2px;
    top: 18px;
    width: 75px;
  }

  .refer-div p {
    margin-top: 0px;
    line-height: 16px;
    font-size: 13px;
  }

  /* ----------------FAQ--------------------- */
  .home-faq {
    padding-left: 0px;
  }

  .Serviceprice_table {
    width: 100% !important;
  }

  .table-container {
    width: 100% !important;
    overflow-x: scroll !important;
  }
}
