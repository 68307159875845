* {
  margin: 0;
  padding: 0;
}

.navbar-container {
  min-height: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar {
  background-color: #122164 !important;
  width: 100%;
}

.navbarToolbar {
  width: 90%;
  margin: 0 5%;
}

.navbarLogo {
  width: 172.5px;
  height: 45px;
  /* flexGrow: 1; */
  cursor: pointer;
}

.navbarLinks {
  float: right;
  width: 100%;
  display: flex;
  justify-content: end;
  text-align: center;
}

.navbarLinks ul {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.navbarLinks ul li {
  margin-left: 2% !important;
  margin: 0 1%;
  list-style-type: none;
}

.links {
  text-decoration: none;
  width: 89px;
  height: 19px;
  font-family: AvenirDemi;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.links Button {
  width: 100%;
  text-decoration: none;
  font-family: AvenirDemi;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #122164;
  background-color: #ffffff;
}

.links Button:hover {
  color: #ffffff;
  background-color: #122164;
}

.navbarMobileCallWAContainer {
  display: flex;
  width: 38%;
  height: 38px !important;
  border-radius: 8px;
  background-color: #7b8bd4;
}

.navbarMobileCallWALine {
  background-color: #fff;
  padding: 1.2%;
  margin: 10% 0;
}

.navbarMobileLogo {
  padding: 6% 10%;
}

.navbarMobileLogo img {
  width: 100%;
}

.Box Link {
  text-decoration: none;
}

.navbarMobileLocationContainer {
  width: 43% !important;
}

.navbarMobileLocationContainer Button {
  font-family: AvenirDemi;
  border: none;
  background-color: #ffffff;
  color: #122164;
  font-size: 2vw;
  height: 60%;
  margin-left: 1%;
  outline: none;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.navbarMobileLocationContainer Button:hover {
  background-color: #122164;
  color: #ffffff;
}

.cart-container {
  display: none;
}


/* desktop search style */
.desktop-search {
  width: 30%;
}

.desktop-search .search-bar-container {
  padding: 0;
}

.desktop-search .search-bar-container .search-bar, .desktop-search .search-bar-container .search-bar input {
  background-color: #E5E5E5;
}

.desktop-search .result {
  width: 25%;
}

.desktop-search .result ul {
  display: inline;
  flex-direction: column;
}

.desktop-search .result ul li {
  color: #000000;
  text-align: start;
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .navbarLogo {
    width: 35vw;
    height: 100%;
  }

  .navbar-container .app-bar {
    padding: 1% 0;
  }


  .navbarMobileLogoContainer {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 98%;
    margin-left: 2%;
  }

  .cart-container {
    display: block;
    /* width: 20%; */
    padding: 0 9%;
  }

  .cart-and-search-container {
    display: flex !important;
    width: 40%;
  }

  .search-modal-body{
    height: 421px;
    position: relative;
  }

  .modal_body .MuiOutlinedInput-root {
    margin: 0 2%;
    width: 96%;
    padding: 0 2% !important;
  }

  .css-jcn4dz-MuiBadge-badge,
  .css-zhwrlg {
    background-color: #7B8BD4 !important;

  }

  .hamburger-button {
    display: flex;
    align-items: center;
  }

  .drawer-header {
    width: 302px;
    height: 55px;
    box-shadow: 0px 2px 10px rgba(135, 135, 135, 0.15);
  }

  .forward-arrow-icon {
    text-align: left;
    color: #000000;
  }

  .login-container {
    width: 178px;
    height: 59px;
    background-color: #f8f8f8;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 62px;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 8px;
    font-weight: 600;
    /* font-family: 'Avenir Next LT Pro'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #122164;
  }

  .menu-name {
    text-align: right;
    /* font-family: 'Avenir Next LT Pro'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #1b1b1b;
  }

  .drawer-location {
    background-color: #e9edfb;
    height: 3vh;
    width: 40vw;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #000000;
    display: inline;
    border-radius: 6px;
    font-weight: bold;
    padding: 1% 2% 2% 2%;
    margin-left: 3%;
  }

  .footer-navbar {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .footer-nav {
    align-self: center;
    margin-bottom: 5% !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    justify-content: center !important;
  }

  .footer-logo {
    padding: 0% 10% 0% 10%;
  }

  .footer-nav-contact {
    align-self: center;
    display: flex;
    justify-content: baseline;
    justify-content: center;
    /* padding-top: 30px; */
  }

  .footer-logo-contact {
    font-weight: 600;
    font-size: 20px;
  }

}

@media screen and (min-width: 767px) {
  .navbarMobileLogoContainer {
    display: none;
  }


}

@media screen and (max-width: 370px) {
  .hamburger-button img {
    margin-left: 40%;
  }

  .navbarMobileLogo img {
    margin-top: 35%;
  }
}