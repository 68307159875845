* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
/*================Blog Container============*/
.blog-container {
  margin: 1.5% 5%;
}
.blogs {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  text-align: justify !important;
}
.blog-img {
  width: 100%;
}
.refer-and-earn {
  background-color: #122164;
  width: 100%;
  margin-top: 2%;
}
.youtubevideo-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}
.blog-carousel {
  display: none;
}
.blogs-boxes {
  background-color: #e5e5e5;
  border: 1px solid red;
  box-sizing: border-box;
}
.blog-card {
  width: 100%;
  height: 100% !important;
  margin: 2% 1% 0% 0%;
  background: #ffffff;
  padding: 1%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  text-transform: none !important;
  text-decoration: none !important;
}
.blogs-images {
  width: 100%;
  height: 70%;
}

.blog-page-heading h3 {
  margin: 5% 0% !important;
  font-size: 38px !important;
  font-weight: 700 !important;
}
.latest-card {
  background: #ffffff;
  padding: 1%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  margin-right: 5% !important;
}
.gativan-youtube-video {
  width: 100%;
  height:350px;
}
.youtube-video {
  margin-left: 3%;
}
.youtube-playlist {
  width: 100% !important;
  height: auto !important;
  background-color: black;
}
.youtubevideo-title:hover {
  background-color: #222;
  cursor: pointer;
}
.youtubevideo-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  padding: 0.5%;
}
.video-title {
  margin-left: 5% !important;
}
.video-time {
  color: white;
  font-size: 12px;
  font-weight: 200;
}

.autor-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3%;
}
.blog-title {
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  text-decoration: none !important;
}
.author-name {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: none !important;
  text-decoration: none !important;
}
.writing-date {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #707070 !important;
  text-transform: none !important;
  text-decoration: none !important;
}
/*========================================Blogs Page==========================================================*/
.blogs-conatainer {
  margin: 5%;
}
.blog-image-container {
  width: 100%;
}
.main-blogs-images {
  width: 100%;
}
.headings-blogs {
  width: 95%;
  margin-top: 2%;
}
.headings-blogs h4 {
  font-size: 45px;
  font-weight: 700;
  text-align: justify;
}
.headings-blogs p {
  margin-top: 2%;
  text-align: justify;
}
.blogs-title {
  margin: 3% 0% !important;
  text-align: justify;
  font-size: 35px;
  font-weight: 700;
}
.treading-blogs {
  margin: 0% 0% 0% 10%;
}
.treading-blogs h4 {
  margin: 3% 0%;
}

.particular-blogs {
  width: 95%;
}
.particular-blogs p {
  margin-top: 2%;
  text-align: justify;
}
.remaining-cards {
  width: 100%;
  margin: 2% 1% 0% 0%;
  background: #ffffff;
  padding: 1%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
}
.remaining-images {
  width: 100%;
}
.treading-blogs h4 {
  margin-top: 5%;
  font-size: 35px;
}

/*======================Mobile View started======================*/
@media screen and (max-width: 768px) {
  .blog-container {
    margin: 1%;
  }
  .refer-latest-featured-electric {
    margin: 2%;
  }
  .blog-img,
  .blogs {
    display: none;
  }

  .blog-carousel-item img {
    width: 100%;
    height: 100%;
  }

  .blog-carousel {
    display: block;
  }
  .refer-and-earn {
    background-color: #122164;
    width: 100%;
    height: 100%;
  }
  .youtube-video {
    margin-left: 0%;
  }
  .gativan-youtube-video {
    width: 100%;
    height:350px;
  }

  .blog-page-heading h3 {
    margin: 5% 0% !important;
    font-size: 25px !important;
    font-weight: 700 !important;
  }
  .latest-card {
    width: 100%;
    margin: 2% 0%;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    margin-right: 0% !important;

  }

  /*===========================blogs container==========*/
  .blog-image-container {
    width: 100%;
  }
  .main-blogs-images {
    width: 100%;
  }
  .particular-blogs p {
    margin-top: 2%;
    text-align: justify;
  }
  .treading-blogs h4 {
    font-size: 25px;
    text-align: justify;
  }
  .headings-blogs {
    width: 100%;
    margin-top: 2%;
  }
}
