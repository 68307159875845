:root {
  --border: 1px solid #E5E5E5;
  --blue: #122164;
}

/* mobile view checkout, view cart, paynow footer */
/* .mobile-view-footer {
  position: sticky !important;
  bottom: 0px !important;
  background-color: red;
} */


/* display scroll bar */
::-webkit-scrollbar {
  display: none;
}

::-moz-scrollbar {
  display: none;
}

::-ms-scrollbar {
  display: none;
}

.rowmainFooter {
  background-color: #122164;
  color: white;
  width: 100%;
  height: 45vh;
  margin-top: -2.5%;
  /* margin-left: 5%; */
}

.rowsubfooter {
  background-color: #707070;
  color: white;
}

.mainFooterBox {
  display: flex;
  justify-content: space-around;
  margin-top: 5.5vh;
  margin-left: "4%";
}

.linktitle {
  color: #fff;
  text-decoration: none;

}

.footertitle {

  color: #fff;
  font-size: 1.2vw;
  margin-left: 1.5vw;
  cursor: pointer;
  margin-top: 10%;
  margin-bottom: 5vh;

  /* src:url("/public/Fonts/AvenirNextLTPro-Bold.otf"); */
  justify-content: center;

}

.subfootercol {
  margin-left: 3%;
  justify-content: center;
}

.logoinfo {
  margin-top: 5vh;
  margin-right: 10%;
  justify-content: center;
  margin: 5px;
}

.footerLogo {
  margin-top: 6vh;
  margin-right: 10%;
  justify-content: center;

}

.logoInfo p {
  margin-top: 3%;
  font-size: 75%;
  /* src:url("/public/Fonts/AvenirNextLTPro-Regular.otf"); */
  /* margin-left: 5%; */
}

.unorderlist li a {

  color: rgba(255, 255, 255, 0.945);
  text-decoration: none;
  margin-left: -6%;
  /* src:url("/public/Fonts/AvenirNextLTPro-Regular.otf"); */

  margin-top: 9%;
  margin-bottom: 43px;

}

.unorderlist li {
  list-style-type: none;
  text-decoration: none;
  margin-top: 13%;
  line-height: 20%;
  margin-bottom: 32px;
  font-weight: initial;
}

.footerlayout {
  width: 99%;

  margin-top: 35px;

}

.logoInfo,
.logoInfo,
.faLogo {
  margin-top: 2vh;
}

/* .appLinkLogo{
  margin-bottom: 30vh;
} */
.logoInfo ul li {
  line-height: 5vh;
  list-style-type: none;
}

.appLinkLogo {
  display: flex;
  flex-direction: column;
  margin-top: 17vh;
}

.androidLogo {
  width: 8vw;
  height: 1.2vh;
  margin-left: 40px;
}

.footerTitle {
  text-align: center;
  font-size: 0.9vw;
  color: #122164;
  font-weight: bold;
  /* src:url("/public/Fonts/AvenirNextLTPro-Bold.otf"); */

}

.footer {
  margin: 2vh;
  padding: 4;
}

.iconSocial {

  color: white;
  width: 4vw;
  height: 4vh;

  padding: 2;
  margin-bottom: 20px;
}

.socialmediaicons {
  margin: "2px";
}

.footerIcon {
  color: white;
  width: 3vw;
  height: 3vh;
}

.footerContent {
  margin: 4px;
  margin-top: 3.9vh;
}

.socialIcons {
  margin-left: -1.5vw;
  margin-top: 2.5vh;
}

.iconFooter {
  color: white;
  width: 4vw;
  height: 4vh;

}



.main {
  width: 100%;
}

/* \================================================================================================ */


@media screen and (max-width:600px) {
  .main {
    width: 100%;
  }

  .HeaderDesktop {
    display: none;
  }

  .main {
    width: 100%;
  }
}