* {
  margin: 0;
  padding: 0;
}

.howGativanWorkContainer {
  margin: 0 3%;
}

.HeadingSection {
  display: inline-flex;
  margin-top: 4%;
  width: 100%;
  margin-bottom: 2%;
  justify-content: baseline;
}

.Bullet {
  width: 14px;
  height: 38px;
  display: inline-block;
  padding: 1.5vh 0px;
  background-color: #122164;
  margin-left: 2%;
  margin-top: 0.4%;
}

.Heading {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.01em;

  color: #000000;
}

.Content {
  width: 90%;
  margin: 2% 0 2% !important;
}

.number {
  /* background-color: #122164;
    border-radius: 40px;
    width: 70px;
    height: 43px;
    text-align: center;
    margin-left: 3%; */

  background-color: #122164;
  width: 43px;
  height: 43px;
  margin-top: 1%;
  margin-left: 3%;
  border-radius: 50%;
  padding: 14px;
  background: #122164;
  border: 3px solid #122164;
  color: #ffff;
  text-align: center;
}

.number h4 {
  font-family: AvenirDemi;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.01em;
  margin-top: -9px;
  margin-left: -40%;
  color: #ffffff;
}

.workscontent {
  width: 100%;
  margin: 0 10% 0 5%;
}

.worktitle {
  font-family: AvenirDemi;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;
  line-height: 55px;
  letter-spacing: 0.01em;
  color: #000000;
}

.workdetails {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #000000;
  /* margin: 2% 0; */
}

.workimg {
  text-align: center;
}

.workimg img {
  width: 85%;
  margin-top: 10px !important;
}

/* Mobile Device */
@media screen and (max-width: 767px) {
  .Bullet {
    width: 8px;
    height: 15px;
    background: #122164;
  }

  .Heading {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #000000;
  }

  .Content {
    width: 100%;
    height: 100%;
  }

  .workscontent {
    width: 85%;
  }

  .number {
    width: 24px;
    height: 24px;
  }

  .number h4 {
    font-size: 12px;
    line-height: 29px;
    margin-top: 0%;
    margin-top: -13px;
    margin-left: -4px;
  }

  .worktitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #000000;
  }

  .workdetails {
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 5px;
  }

  .workimg img {
    width: 80%;
  }
}

/* media query for laptop and tablet */
@media screen and (max-width: 1440px) and (min-width: 768px) {
  .worktitle {
    font-size: 26px !important;
  }
}
