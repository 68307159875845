* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.checkout-Container {
  display: grid;
  grid-template-columns: 65% auto;
  grid-template-rows: 100% auto;
  margin: 5% 5%;
  overflow: hidden;
}

/*------------view cart details--------------------------------*/
.view-cart-details {
  height: 100% !important;
  grid-column: 2/-1;
  grid-row: 1/-1;
  margin-left: 5%;
}

/*----------------------Enter Mobile Number----------------------*/
.mobile-number {
  background-color: #ffffff;
  padding: 2%;
  border: 1px solid #e5e5e5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.header {
  font-family: AvenirBold;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 137.19%;
  /* identical to box height, or 36px */

  letter-spacing: 0.02em;

  color: #000000;
}

.mobile-number-input {
  background-color: #e5e5e5;
  width: 43%;
  height: 20%;
  padding: 1%;
  border-radius: 4px;
}

.mobile-number-input span {
  /* font-family: 'Avenir Next LT Pro'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 137.19%;
  /* identical to box height, or 22px */

  letter-spacing: 0.02em;

  color: #858585;
}

.mobile-number-input-field {
  margin-left: 3%;
  background-color: #e5e5e5;
  border: 0px solid #e6e6e6;
  outline: none;
  width: 85%;

  /* font-family: 'Avenir Next LT Pro'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 137.19%;
  /* identical to box height, or 22px */

  letter-spacing: 0.02em;
}

.mobile-number-input span,
.mobile-number-input-field {
  font-family: AvenirRegular;
  margin-top: 1%;
}

.mobile-number-btn {
  margin-top: 3% !important;
  padding: 1% 2% !important;
  /* border: 1px solid #6A7086 !important; */

  font-family: AvenirDemi !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  /* identical to box height, or 22px */

  letter-spacing: 0.02em !important;

  color: #ffffff !important;
  background-color: #7b8bd4 !important;
  text-transform: none !important;
}

#mobile-number-validation {
  color: #ff0f0f;
  display: none;
}

/*-----------------Pick and Drop---------------------------------*/

.service-type-empty-container {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  /* height: 15vh; */
  margin-top: 3%;
}

.service-type-empty-container h4 {
  font-family: AvenirBold;
  color: #707070;
  padding: 2%;
}

.pick-and-drop {
  display: flex;
  margin-top: 3%;
  /* height: 250px; */
  /* background: #FFFFFF;
    border: 1px solid #E5E5E5; */
  list-style-type: none;
  /* padding: 2%; */
  transition: all 2sec !important;
}

.pick-and-drop li {
  width: 100%;
}

.service-type {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 3%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}

.service-label {
  font-family: AvenirBold;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 137.19%;
  /* identical to box height, or 36px */

  letter-spacing: 0.02em;

  color: #000000;
}

.service-desc {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 137.19%;
  /* or 22px */

  letter-spacing: 0.02em;

  color: #000000;
}

/*----------Select Date and Time Slot---------------------------*/
.date-time-slot {
  margin-top: 3%;
  padding: 2%;
  /* height: 25%; */
  background: #ffffff;
  border: 1px solid #e5e5e5;
  transition-property: width;
  transition-duration: 3s;
}

.date-slots-subcontainer {
  display: flex;
  width: 100%;
}

.date-header {
  margin: 1% 0;
  display: flex;
  /* font-family: 'Avenir Next LT Pro'; */
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 137.19%;
  /* identical to box height, or 29px */

  color: #707070;
}

.date-header h4 {
  font-family: AvenirDemi;
  margin-left: 1%;
}

.date-slots {
  padding-top: 20px;
  border: 1px solid #000000;
  height: 120px;
  /* width: 6vw; */
  margin-right: 3vw;
  /* text-align: center; */
}

.date-slots h4 {
  font-family: AvenirDemi;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 137.19%;
  /* identical to box height, or 22px */

  letter-spacing: 0.02em;
}

.date-slots h2 {
  font-family: AvenirDemi;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 137.19%;
  /* identical to box height, or 36px */

  letter-spacing: 0.02em;
}

.date-slots,
.time-slots {
  border: 1px solid black;
  text-align: center;
  /* background-color: #FFFFFF !important;
    color: #000000 !important; */
}

.time-header {
  margin: 1% 0;
  display: flex;
  /* font-family: 'Avenir Next LT Pro'; */
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 137.19%;
  /* identical to box height, or 29px */

  letter-spacing: 0.02em;

  color: #707070;

  display: flex;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  margin-top: 3%;
}

.time-slots-header {
  display: flex;
  margin-left: 1%;
  font-family: AvenirDemi;
}

.time-slots-header h4 {
  font-family: AvenirDemi;
}

.time-slot-container {
  margin-top: 3%;
}

.time-slots {
  padding: 8% 0;
  padding-top: 40px;
  border: 1px solid #000000;
  height: 120px;
  /* width: 6vw; */
  margin-right: 3vw;
  /* text-align: center; */
}

.time-slots h4 {
  font-family: AvenirDemi;
}

.time-slots h6 {
  color: #707070 !important;
  margin-top: 2%;
}

.date-time-btn {
  margin-top: 3% !important;
  padding: 1% 0 !important;
  width: 30% !important;
  border: 1px solid #707070 !important;

  /* font-family: 'Avenir Next LT Pro'; */
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 120% !important;
  /* or 19px */

  color: #000000 !important;
}

/*-----------Enter Your Address---------------------------------*/
.enter-address {
  margin-top: 3%;
  padding: 2%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}

.address-house,
.address-area,
.address-pincode,
.address-landmark {
  margin: 2% 10% 0 0;
  width: 50%;
  padding: 2%;
  background: #ffffff;
  border: 1px solid #e5e5e5;

  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 137.19%;
  /* identical to box height, or 22px */

  letter-spacing: 0.02em;

  color: #000000;
}

.save-address-checkbox {
  margin-top: 2%;
}

.use-this-address {
  margin-top: 3% !important;
  width: 20%;
  padding: 2%;
  /* border: 1px solid #707070 !important; */

  text-align: center;
  height: 5.4vh !important;
  color: #ffffff !important;
  background-color: #7b8bd4 !important;
  text-transform: none !important;

  font-family: AvenirBold !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 26px !important;
  line-height: 31px !important;
}

.or {
  margin-top: 3%;
  width: 40%;
  text-align: center;
}

.or h4 {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 137.19%;
  /* identical to box height, or 22px */

  letter-spacing: 0.02em;

  color: #000000;
}

.use-current-location {
  margin-top: 3% !important;
  width: 40%;
  /* padding: 2%; */
  background: #122164 !important;
  text-transform: none !important;

  /* font-family: 'Avenir Next LT Pro'; */
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  /* identical to box height, or 22px */

  letter-spacing: 0.02em !important;

  color: #ffffff !important;
}

.location-red-logo {
  margin: 0 2%;
  width: 8%;
}

.pickup-address {
  display: none;
}

/*--------------Select Payment Method----------------------------*/
.payment-method {
  margin-top: 3%;
  padding: 2%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}

/*----------------Payment UPI-------------------------------------*/
.payment-UPI {
  margin-top: 3%;
  padding: 2%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}

.payment-unordered-list {
  list-style-type: none;
}

.upi-section {
  display: flex;
  margin-top: 3%;
}

.img-upi-section {
  width: 10%;
}

.img-upi-section img {
  width: 70%;
}

.label-upi-section {
  width: 80vw;
  text-align: start;
  margin-top: 1%;
}

.label-upi-section h4 {
  font-family: AvenirBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 137.19%;
  /* identical to box height, or 22px */

  letter-spacing: 0.02em;

  color: #707070;
}

.radio-btn-upi-section {
  width: 10vw;
  text-align: end;
}

.radio-btn-upi-section input {
  width: 40%;
  height: 40%;
}

/*---------------Pay After Service---------------------------------*/
.pay-after-Service {
  margin-top: 3%;
  padding: 2%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}

/*-----------------Credit Debit Card-------------------------------*/
.credit-debit-card {
  margin-top: 3%;
  height: 100px;
  background-color: black;
  color: white;
}

/*-----------------Wallets------------------------------------------*/
.wallets {
  margin-top: 3%;
  height: 200px;
  background-color: cadetblue;
}

/*-----------------EMI-----------------------------------------------*/
.emi {
  margin-top: 3%;
  height: 100px;
  background-color: #e3e7ea;
}

/*------------------Note----------------------------------------------*/
.note {
  margin-top: 3%;
  height: 120px;
  background-color: #122164;
}

/*-------------------------Service Cart--------------------------------*/
.service-cart {
  margin: 3% 3%;
  height: 250px;
  background-color: #122164;
}

/*-------------------Recommended Services-----------------------------*/
.recommended-services {
  margin: 3% 0%;
  height: 150px;
  background-color: #51ae3a;
}

/*-------------------------------Apply Promocode----------------------*/
.apply-Promocode {
  margin: 3% 3%;
  height: 100px;
  background-color: #707070;
}

/*-------------------------------Bill Details-------------------------*/
.bill-details {
  margin: 3% 3%;
  height: 100px;
  background-color: #7b8bd4;
}

/*----------------------------Pay Button-----------------------------*/
.pay-button {
  margin: 3% 3%;
  height: 80px;
  background-color: #ff0f0f;
}

.mobile-view-footer {
  position: sticky !important;
  bottom: 0px !important;
}

/*-----------------------------------------------------------------------------------Checkout Mobile View----------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 768px) {
  .checkout-Container {
    grid-template-columns: 100%;
    grid-template-rows: 100% 100% auto;
    margin: 2.5% 2.5%;
  }

  /*----------------------Enter Mobile Number----------------------*/
  .mobile-number {
    padding: 3%;
  }

  .header {
    font-size: 16px;
    margin-bottom: 3%;
  }

  .mobile-number-input {
    padding: 2%;
    width: 100%;
    height: 25%;
  }

  .mobile-number-input span,
  .mobile-number-input-field {
    font-weight: 400;
    color: #000000;
  }

  .mobile-number-input span,
  .mobile-number-input-field {
    margin-top: 1%;
  }

  .mobile-number-btn {
    padding: 2% !important;
    width: 100% !important;
    height: 30% !important;
    background: #7b8bd4 !important;
    border-radius: 4px !important;

    font-weight: 600 !important;

    text-align: center !important;

    color: #ffffff !important;
  }

  /*-----------------Pick and Drop---------------------------------*/

  .service-type-empty-container {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    /* height: 15vh; */
    margin-top: 3%;
  }

  .service-type-empty-container h4 {
    padding: 3%;
  }

  .enter-address {
    padding: 3%;
  }

  .address-house,
  .address-area,
  .address-pincode,
  .address-landmark {
    width: 100%;
  }

  .MuiTypography-root {
    font-size: 15px !important;
  }

  .use-this-address {
    width: 30%;

    /* font-family: 'Avenir Next LT Pro' !important; */
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: 0.01em !important;

    color: #ffffff !important;
  }

  .pick-and-drop {
    display: inline-block;
    width: 100%;
    background: #ffffff;
    border: 0px solid #e5e5e5;
    border-radius: 8px;
    /* padding: 3%; */
  }

  .service-type {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 3%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
  }

  .service-type-name {
    width: 90%;
  }

  .service-label {
    font-size: 16px;
  }

  .service-desc {
    font-size: 12px;
  }

  .radio-btn {
    font-size: 12px;
  }

  .divider {
    border: 1px solid #e5e5e5;
  }

  /*----------Select Date and Time Slot---------------------------*/
  .date-time-slot {
    padding: 3%;
    /* height: 30%; */
  }

  .date-header,
  .time-clock-icon {
    display: none !important;
  }

  /* .date-slots-container {
        width: 190vw;
        height: 10vh;
    } */

  .date-slots {
    height: 7vh;
    padding-top: 12px;
    border-radius: 8px;
  }

  .date-slots h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 137.19%;
    /* or 16px */

    letter-spacing: 0.02em;
  }

  .date-slots h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 137.19%;
    /* or 16px */

    letter-spacing: 0.02em;
  }

  .time-header h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 137.19%;
    /* identical to box height, or 22px */

    letter-spacing: 0.02em;

    color: #000000;
  }

  .time-header {
    font-size: 16px;
    color: #000000;
  }

  .time-header p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 137.19%;
    /* identical to box height, or 22px */

    letter-spacing: 0.02em;

    color: #707070;
  }

  .time-slots-header span {
    font-weight: 400;
    font-size: 16px;

    color: #707070 !important;
  }

  .time-slots {
    padding: 5% 0;
    padding-top: 4%;
    border: 1px solid #000000;
    height: 6vh;
    margin-right: 3vw;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-slots h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 137.19%;
    /* or 16px */

    letter-spacing: 0.02em;
  }

  .time-slots h6 {
    font-weight: 400 !important;
    color: #707070 !important;
  }

  .date-time-btn {
    font-size: 12px !important;
  }

  /*-----------Enter Your Address---------------------------------*/
  .pickup-address-header {
    display: flex;
    padding: 3%;
  }

  .location-blue-logo {
    width: 22px;
    height: 22px;
  }

  .pickup-address {
    display: block;
    margin-top: 3%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    overflow: hidden;
  }

  .add-pickup-address {
    width: 100%;
    height: 100%;
    border: 0px solid #e5e5e5;
    padding: 3%;
  }

  .add-pickup-address::placeholder {
    justify-content: start;
  }

  /*--------------Select Payment Method----------------------------*/
  .payment-method {
    padding: 3%;
    margin-bottom: 6rem;
  }

  /*----------------Payment UPI-------------------------------------*/
  .payment-UPI {
    padding: 3%;
  }

  .img-upi-section {
    width: 15%;
  }

  .img-upi-section img {
    width: 70%;
  }

  .label-upi-section {
    width: 60vw;
  }

  .radio-btn-upi-section input {
    width: 40%;
    height: 40%;
  }

  /*-----------------------View Cart and Pick and drop----------------------*/
  .view-cart-details

    /* .pick-and-drop, */
    /* .enter-address  */ {
    display: none;
  }
}
