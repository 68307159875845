.workshop-carousel-container {
    width: 98%;
    margin-left: 1%;
}

.image-container {
    cursor: pointer;
}

.image-container img {
    border-radius: 8px;
    width: 100%;
}

.map-modal {
    width: 100%;
}

.map-container {
    position: absolute !important;
    top: 47% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 85% !important;
    height: 80% !important;
    background-color: 'background.paper';
    border: '2px solid #000';
    box-shadow: 24;
    padding: 4;
    position: relative;
}

.modal-title {
    padding: 1% 2%;
    display: flex;
    justify-content: space-between;
    background-color: aliceblue;
    width: 100% !important;
}

.map-container .modal-title h2 {
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0.01em;

    color: #000000;
}

.map-container .modal-title .clear-icon {
    cursor: pointer;
}

.modal-description,
.map-iframe {
    width: 100% !important;
    height: 100% !important;
}

.map-iframe {
    z-index: 1 !important;
}

.button-container {
    width: 17%;
    height: 10%;
    z-index: 9999 !important;
    cursor: pointer;
    pointer-events: all;
    padding: 1%;
    /* background-color: #122164; */
    color: #ffffff;
    position: absolute;
    top: 95%;
    left: 5%;

}

.button-container button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;

    /* White  */

    color: #FFFFFF;
    background-color: #122164;

}

@media screen and (max-width:768px) {
    .map-container {
        top: 48% !important;
        width: 90% !important;
        height: 90% !important;
    }

    .modal-title {
        padding: 2%;
    }

    .map-container .modal-title h2 {
        font-family: AvenirBold;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        letter-spacing: 0.01em;

        color: #000000;
    }

    .button-container {
        top: 90%;
        width: 45%;
        height: 8%;
    }

    .button-container button {
        font-family: AvenirBold;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;

        color: #FFFFFF;
    }
}