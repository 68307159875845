* {
    margin: 0;
    padding: 0;
}

.service-container {
    width: 98%;
    height: auto;
    margin: 1%;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px rgba(125, 125, 125, 0.14);
    overflow: hidden;
}

.service-btn {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.service-content-container {
    background-color: #ffffff;
    color: black;
    filter: grayscale(100%);
    width: 9vw;
    height: 100%;
    text-align: center;
    padding-bottom: 5%;
}

.service-image-container {
    width: 9vw;
    height: 8vh;
}

.service-image-container img {
    width: 50%;
    /* width: 4vw;
    height: 8vh; */
}



.service-text-container h4 {
    font-family: AvenirDemi;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 137.19%;
    /* identical to box height, or 22px */

    text-align: center;

    color: #000000;
    text-transform: none;

}

.service-menu-link {
    text-decoration: none;
}

.service-container .MuiButton-root {
    padding: 0px 8px !important;
}


@media screen and (max-width:768px) {
    .service-container {
        width: 98%;
        /* height: 85%; */
    }

    .service-image-container {
        width: 100%;
        height: 65%;
        /* height: auto; */
    }

    .service-content-container {
        width: 27vw;
        height: 12vh;
    }

    .service-image-container img {
        width: 60%;
        /* width: 15vw;
        height: 7vh; */
    }

    .service-text-container h4 {
        margin-top: 5%;
        font-size: 12px;
    }
}