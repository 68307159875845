.menu {
    display: inline-flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
}

.menu::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.service-container {
    width: 100%;
    height: auto;
    margin: 1% 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px rgba(125, 125, 125, 0.14);
    /* overflow: hidden; */
}

.App {
    overflow-x: scroll !important;
}

.slide.active .service-text-container h4,
.active .service-text-container h4 {
    font-weight: 800;
    color: #000000;

    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 137.19%;
    /* or 16px */

    text-align: center;

    color: #000000;
}

.service-text-container h4 {
    font-family: AvenirDemi;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 137.19%;
    /* or 16px */

    text-align: center;

    color: #121212;
}

.slide {
    flex: 0 0 auto;
    /* height: 250px;
    width: 220px; */
    /* width: 100%; */

    text-align: center;
}

.slide.active,
.active {
    /* border: 2px solid #000;
    filter: grayscale(0%) !important; */
    background-color: rgb(233, 237, 251);
    filter: grayscale(0%) !important;
    border-top: 8px solid rgb(123, 139, 212);
}

/* .service-image-container{
    height: 12vh;
    width: 15vw;
} */

@media screen and (max-width:768px) {
    .slide {
        /* height: 150px;
        width: 120px; */
        width: 30%;
    }

    .service-btn {
        height: 12vh;
    }

    .service-container {
        width: 100%;
        height: auto;
    }

    .service-image-container {
        height: 65%;
        width: 100%;
    }

    .service-text-container {
        margin-top: 5%;
    }
}