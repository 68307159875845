*{
  padding: 0px;
  margin: 0px;
  box-sizing: border;

}
.accessories-image-container{
  display: grid;
  grid-template-columns: 65% auto;
  grid-template-rows: 80vh auto;
}
.accessories-background-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../../public/Assets/accessories/car duster image.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  grid-column: 1/-1;
  grid-row: 1/2;
}
.accessories-card {
  grid-column: 2/-1;
  grid-row: 1/3;
  margin-right: 7%;
  margin-left: 2%;
  z-index: 2;
  position: relative;
  padding-left: 5%;
  height: 50%;
}
.accessories-contain {
  width: 100%;
  background-color: white;
  padding: 4% 3%;
  position: static;
  top: 75%;
  box-shadow: 0px 0px 5px;
  overflow: auto;
  position: absolute;
  /* bottom: 10%; */
  right: 2%;
}
.btn__container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 5px;
  width: 8vw;
  margin-top: 3% !important;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.accessories-price strong {
  padding: 5%;
}
.control__btn {
  font-size: 25px;
  padding: 10px 20px;
  background-color: transparent;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  font-weight: 600;
}
.counter__output {
  font-size: 25px;
  color: #122164;
  font-weight: 600;
  margin-top: 7%;
}
.control__btn:hover {
  background-color: rgb(16, 0, 54);
  color: rgb(255, 255, 255);
}
.accessories-main-contain {
  margin: 4%;
}
.add-to-cart-btn {
  background-color: #122164 !important;
  color: white !important;
  margin-top: 5% !important;
  width: 10vw !important;
  height: 6vh !important;
  text-transform: none !important;
  font-size: 18px !important;
}
.accessories-desp {
  padding: 0% 5%;
}
.accessories-desp strong , .accessories-desp  p , .accessories-desp ul li{
  font-size: 16px !important;
  font-family: 'Avenir Next LT Pro';
  line-height: 1.5rem;
}
.accessories-price {
  margin-top: 5%;
}
.contact-more-info {
  margin-top: 3%;
  background-color: #f8f8f8;
  /* height: 250px; */
  text-align: center;
  padding: 2% 0%;
  font-size: 25px;
}
.contact-info {
  font-size: 22px;
  padding-top: 5% !important;
}
.contact-us-email {
  padding: 2% 0%;
  display: block;
}
.contact-us-email span {
  padding-top: 5%;
}
.call-me-btn {
  background-color: #008000 !important;
  text-decoration: none !important;
  text-transform: none !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: 600 !important;
}
@media screen and (min-width:1068px) {
    .duster-image{
        display: none;
    }
}
/* ----------------mobile view started--------- */

@media screen and (max-width: 768px) {
  .accessories-image-container {
    grid-template-columns: 100%;
    grid-template-rows: 40vh auto;
  }
  .accessories-background-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("../../../public/Assets/accessories/Car Duster Hero.jpeg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    grid-column: 1/-1;
    grid-row: 1/2;
  }
  .accessories-card {
    grid-column: 1/2;
    grid-row: 2/3;
    margin: 0%;
    margin-top: 20px;
    padding: 0px;
    display: flex;
    justify-content: center;
    background-color: #212164;
  height: auto;
  }
  .accessories-contain {
    position: static;
    width: 100%;
    height: auto;
    box-shadow: none;

  }
  .accessories-contain strong, .accessories-contain p{
    font-family: 'Avenir Next LT Pro';

  }
  .accessories-image {
    background-color: white;
    align-items: center;
    justify-content: center;
  }
  .btn__container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 5px;
    width: 30vw;
    margin-top: 3% !important;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .add-to-cart-btn {
    background-color: #122164 !important;
    color: white !important;
    margin-top: 5% !important;
    width: 40vw !important;
    height: 6vh !important;
    text-transform: none !important;
    font-size: 18px !important;
    text-decoration: none !important;
  }
  .contact-more-info {
    margin-top: 3%;
    background-color: #f8f8f8;
    /* height: 250px; */
    text-align: center;
    padding: 2% 0%;
    font-size: 25px;
  }
  .call-me-btn {
    background-color: #008000 !important;
    text-decoration: none !important;
    text-transform: none !important;
    color: white !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    height: 6vh !important;
  }
  .duster-image{
    margin-top: 3% !important;
  }
  .accessories-desp {
    padding: 0% 0%;
    margin-top: 4%;

  }
  .accessories-desp p  {
    font-size: 16px !important;
    font-family: 'Avenir Next LT Pro';
    line-height: 1.5rem;
    margin:3% 0%;

  }
  .accessories-desp strong ,.accessories-desp ul li{
    font-size: 16px !important;
    font-family:AvenirDemi;
    line-height: 1.5rem;
  }
 
}