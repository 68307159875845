.search-bar-container {
    width: 100% !important;
    padding: 5% 4% 0 4%;
}


.search-bar {
    /* margin: 0 2%; */
    padding: 1%;
    display: flex;
    border: 1px solid #B1B1B1;
    border-radius: 5px;
    color: #767474;
}

.search-bar input {
    color: #d2d1d1;
    padding: 0 1%;
    border: none;
    outline: none;
    width: 100%;
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;

    color: #000000;

    opacity: 0.8;
}



.result {
    /* padding: 0 2%; */
    position: absolute;
    height: 35vh;
    overflow: scroll;
    border-radius: 5px;
    /* margin-left: 2%; */
    z-index: 2;
    width: 96%;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.result ul li {
    list-style-type: none;
}

/* .result::-webkit-scrollbar {
    display: none;
} */

/* .result { */
/* -ms-overflow-style: none; */
/* IE and Edge */
/* scrollbar-width: none; */
/* Firefox */
/* } */

.result ul li {
    padding: 2%;
}

.result ul li:hover {
    background-color: #B1B1B1;
    color: #FFFFFF
}


@media screen and (max-width: 768px) {
    .search-bar {
        padding: 2% 1%;
    }

    .search-bar input {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        /* identical to box height */


        color: #000000;

        opacity: 0.8;
    }

    .result {
        width: 89%;
    }
}