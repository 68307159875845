.menu-section {
    width: 100%;
    height: 100%;
    background-color: #707070;
    padding: 2% 2% 2% 3%;
    display: flex;
    cursor: default;
}

.section {
    margin-right: 1vw;
}

.about-us-container {
    width: 20%;
    height: 100%;
}

.menu-section h2 {
    margin-bottom: 4vh;
    font-family: AvenirDemi;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;

    color: #FFFFFF;
}

.menu-section h4 {
    cursor: pointer !important;
    margin-bottom: 2vh;
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 50px;

    color: #FFFFFF;

}

.our-services-container {
    width: 22%;
    height: 100%;
}

.car-brands-container {
    width: 18%;
    height: 100%;
}

.bike-brands-container {
    width: 18%;
    height: 100%;
}

.area-container {
    width: 30%;
}

.accordion {
    background-color: #707070 !important;
    font-family: 'Avenir Next LT Pro';
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: 0.01em !important;

    color: #FFFFFF !important;
}

.expand-icon {
    color: #FFFFFF !important;
}

.accordion-summary {
    font-family: 'Avenir Next LT Pro';
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: 0.01em !important;

    color: #FFFFFF !important;
}

.accordion-details {
    font-family: 'Avenir Next LT Pro';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 50px !important;
    letter-spacing: 0.01em !important;

    color: #FFFFFF !important;
    cursor:  pointer !important;
}

/* Gativan Information section */

.gativan-contact-grid {
    display: flex;
    background-color: #122164;
}

.gativan-info-section {
    width: 100%;
    height: 585px;
    background-color: #122164;
    display: flex;
}

.gativan-logo-info-container {
    width: 40%;
    margin: 0 10% 0 3%;
}

.gativan-full-logo {
    width: 100%;
}

.gativan-full-logo img {
    width: 387.17px;
    /* height: 101px; */
    margin-top: 41px;
}

.gativan-desc {
    width: 100%;
}

.gativan-desc {
    margin: 5% 0;
}

.gativan-desc p {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 19px;
    text-align: justify;
    color: #FFFFFF;
}

.social-site-logo {
    width: 100%;
}

.social-site-logo img {
    margin-right: 7%;
}

.facebook-icon {
    width: 11px;
}

.twitter-icon {
    width: 25px;
}

.instagram-icon {
    width: 25px;
}

.contact-section-container {
    width: 100%;
    margin-top: 2%;
}

.contact-section {
    display: flex;
    width: 100%;
    height: 20%;
}


.contact-section img {
    margin-right: 2vw;
}


.contact-section h4 {

    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;

    color: #FFFFFF;

}

.contact-section h5 {
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;

    color: #FFFFFF;
}

.contact-section-key-container,
.contact-section-value-container {
    margin-top: 1%;
}



.contact-section-key-container {
    text-align: start;
    width: 14vw;
}

.kalyani-studio-logo-container {
    display: inline-block;
    margin-top: 30%;
}

.kalyani-studio-logo-container img {
    width: 70%;
}

.copyright-container {
    text-align: center;
    padding: 2% 0;
}

.copyright-container p {
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;

    color: #122164;
}

.accordian-container{
    z-index: 0 !important;
}


@media screen and (max-width:768px) {

    .css-67l5gl.Mui-expanded,
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 0px 0 !important;
    }

    .css-u7qq7e,
    .css-15v22id-MuiAccordionDetails-root {
        padding: 0px 16px 16px !important;
    }

    .accordion-details {
        font-family: AvenirRegular !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 40px !important;
        letter-spacing: 0.01em !important;

        color: #FFFFFF !important;
    }

    .gativan-contact-grid {
        display: inline-block;
    }

    .gativan-info-section {
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-block;
        background-color: #FFFFFF;
    }

    .gativan-logo-info-container {
        width: 100%;
        padding: 2% 6.67% 2%;
        margin: 0;
    }

    .gativan-full-logo img {
        width: 231.15px;
        height: 60px;
        margin-top: 30px;
    }

    .gativan-desc p {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 14px !important;

        color: #FFFFFF;
    }

    .social-site-logo img {
        margin-right: 7%;
    }

    .facebook-icon {
        width: 11px;
    }

    .twitter-icon {
        width: 25px;
    }

    .instagram-icon {
        width: 25px;
    }

    /* Contact Section */

    .contact-section {
        height: 8vh;
    }

    .contact-section img {
        margin-right: 8vw;
    }

    .contact-section h4 {
        margin-right: 5vw;

        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;

    }

    .contact-section h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;

        color: #FFFFFF;
    }

    .contact-section-container {
        padding: 2% 0 0 10%;
        text-align: center;
    }

    .contact-section-image-container {
        width: 14vw;
    }

    .contact-section-key-container {
        text-align: start;
        width: 30vw;
    }

    .contact-section-key-container,
    .contact-section-value-container {
        margin-top: 2%;
    }

    .email-section img {
        width: 30px;
    }

    .phone-section img {
        width: 30px;
    }

    .calendar-section img {
        width: 27px;
    }

    .clock-section img {
        width: 22px;
    }

    .kalyani-studio-logo-container {
        width: 100%;
        background-color: #FFFFFF;
        margin-top: 0;
    }

    .google-play-logo {
        display: none;
    }

    .copyright-container p {
        font-style: normal;
        font-weight: 700;
        font-size: 12px !important;
        line-height: 14px;
        letter-spacing: 0.01em;

        color: #000000;

    }

}


/* media query for laptop and tablet */
@media screen and (max-width:1440px) and (min-width:768px) {
    .menu-section h2 {
        font-size: 22px;
    }

    .menu-section h4 {
        font-size: 16px;
    }

    .gativan-full-logo img {
        width: 200px;
    }

    .gativan-desc p {
        font-size: 12px !important;
    }

    .gativan-info-section {
        height: 450px;
    }

    .contact-section img {
        width: 50%;
    }

    .contact-section h4,
    .contact-section h5 {
        font-size: 20px;
    }
}