.locationBox {
      outline: none !important;
      background-color: white !important;
      border: none; 
      height:30px !important;
      width: 100% !important;
      padding: 2% 6%;
      margin-left: 5%;
      font-size: 16px;
      font-weight: 600;
}


@media screen and (max-width:768px) {
      .locationBox{
            padding: 0% !important;
            width: 100px !important; 
            margin: 0% !important;
      }

      .MuiFormControl-root {
            margin: 0 !important;
            min-width: 100px !important;
      }
}