.periodic-packages {
    /* background-color: rgb(126, 233, 224); */
    /* height: 250px; */
    margin-top: 2%;
}

.additional-jobs {
    /* background-color: darkgoldenrod; */
    /* height: 250px; */
    margin-top: 3%;
}

.periodic-packages h1 {
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 137.19%;
    /* identical to box height, or 58px */


    color: #000000;
}


@media screen and (max-width:768px) {
    .periodic-packages h1 {
        font-family: AvenirBold;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */
    
    
        color: #000000;
    }
}

