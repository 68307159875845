.viewcart-footer {
  margin-top: 2%;
  border: var(--border);
  background-color: var(--blue);
  border-radius: 6px;
  padding: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  width: 100% !important;
  position: sticky !important;
  /* position: fixed !important; */
  bottom: 0 !important;
}

.viewcart-footer-container {
  display: flex !important;
  justify-content: space-between;
}

.viewcart-footer-total-items-dekstop {
  display: none;
}

.viewcart-footer-total-items {
  /* background-color: red; */
  text-align: center;
  min-width: 30%;
}

.viewcart-footer-total-items .added-items {
  font-family: AvenirRegular;
  font-weight: 600;
  color: #fff;
  font-size: 2.8vw;
}

.viewcart-footer-total-items h6 {
  font-family: AvenirDemi;
  color: #fff;
  font-weight: 600;
  font-size: 3.5vw;
}

.footer-total-rupee-icon {
  font-size: 3.8vw !important;
  margin: 0%;
}

.viewcart-footer-youpay {
  display: none;
  /* hidden in mobile view */
}

.viewcar-footer-checkout-btn .checkout-button {
  font-family: AvenirBold !important;
  background-color: white !important;
  color: #122164 !important;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 5vw;
}

.viewcar-footer-checkout-btn .right-arrow {
  font-size: 5vw;
  margin-left: 5%;
  stroke: #122164;
  stroke-width: 1.5;
}

@media screen and (max-width: 768px) {
  .viewcart-footer {
    position: fixed !important;
    padding: 2% 5% 2% 3% !important;
  }

  .viewcar-footer-checkout-btn {
    width: 48%;
  }

  .checkout-button {
    width: 100%;
    padding: 3px 8px !important;
  }
  .view-detailed-bill-link {
    color: #7b8bd4;
    font-size: 0.5rem;
    text-decoration: underline;
  }

  .footer-total-rupee-icon-view-cart {
    font-size: 1.1rem !important;
  }
}

@media screen and (min-width: 768px) {
  /* .viewcart-footer {
          position: fixed !important;
          bottom: 0px !important;
          width: 100% !important;
     } */

  .viewcart-footer-total-items-dekstop {
    display: block;
    cursor: pointer;
  }

  .viewcart-footer-youpay {
    display: block;
    color: #fff;
    font-size: 1vw;
  }

  .viewcart-footer-youpay .rupee-icon {
    font-size: 1vw;
    font-weight: 700;
  }

  .checkout-button {
    display: none !important;
  }

  .viewcart-footer-total-items {
    display: none;
  }

  .viewcart-footer-total-items-dekstop h6 {
    font-size: 1.2vw;
    color: #fff;
  }

  .footer-total-rupee-icon-view-cart {
    font-size: 0.5vw !important;
  }
}
