* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*----------------No Order history Container-----------*/
.no-order-history-container {
  margin: 5%;
}
.no-order-history-container h2 {
  text-align: justify;
}
.no-order {
  padding: 2%;
  margin-bottom: 4%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  height: 75%;
}
.no-order h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #1b1b1b;
  margin: 2% 0%;
}
.book-service-now-btn {
  margin-top: 12%;
}
.book-service-btn {
  width: 272px;
  height: 43px;
  background: #122164 !important;
  border-radius: 4px;
  text-decoration: none !important;
  text-transform: none !important;
}
/*---------======--------Order History Mobile view------===========-*/
@media screen and (max-width: 768px) {
  .no-order {
    padding: 2%;
    margin-bottom: 4%;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #e5e5e5;
  }
}
