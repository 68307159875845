.loader-wrapper {
  display: flex;
  justify-content: center;
}
.loading-spinner {
  width: 50px;
  height: 50px;
  background: linear-gradient(45deg, #122164 30%, white);
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;  
  animation: spinner 1s linear infinite;
}

.loading-spinner::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
