* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
/*==============gativan image container-================*/
.img-container {
  position: relative;
  text-align: center;
  color: white;
}
/*---------login background image----------*/
.login-background {
  width: 100%;
}
/*------------back to booking--------*/
.back-to-booking {
  position: absolute;
  top: 70px;
  left: 100px;
  display: flex;
  align-items: center;
}
/*-----------gativan logo------------------*/
.gativan-logo {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
/*----------social media logo-----------*/
.social-media-logo {
  position: absolute;
  bottom: 10%;
  left: 75px;
  width: 25%;
  padding: 2%;
  display: flex;
  justify-content: space-between;
}
/*-----------facebook logo----------*/
.facebook-logo {
  width: 2%;
  height: 2%;
}
/*---------twitter logo-----------*/
.twitter-logo {
  margin-left: 8%;
  width: 2%;
  height: 2%;
}
/*----------instagram logo------------*/
.instagram-logo {
  margin-left: 8%;
  width: 2%;
  height: 2%;
}

/*========================Otp Form section=====================*/

/*-------otp form container-----*/
.otp-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
/*---------otp form-------*/
.otp-form {
  margin: auto;
  align-items: center;
  justify-content: center;
  display: block;
  width: 100%;
  margin: 5%;
}
/*----------otp verification code---------*/
.otp-form h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 72px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: left;
}
/*-------otp verification msg-----------*/
.otp-form h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 137.19%;
  color: #121212;
  text-align: left;
}
/*------------Text field otp section-------*/
.text-field-otp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin-top: 2%;
}

/*--------enter otp text field----*/
.enter-otp {
  width: 10%;
  height: 20%;
  background: #e5e5e5;
  border-radius: 4px;
  margin: 0 10px;
}
/*-----------otp resend--------*/
.otp-form h6 {
  margin-top: 2%;
  color: #7b8bd4;
}
/*--------sign up--------*/
.sign-up {
  margin: 5% 0%;
}
/*---------------Otp verify button--------*/
.verify-btn {
  background-color: #7b8bd4 !important;
  color: white !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  letter-spacing: 0.02em !important;
  color: white !important;
  text-transform: none !important;
  width: 100% !important;
  height: 50px !important;
  margin-top: 5% !important;
  align-items: flex-start;
}
/*=======================Mobile View Started OTP Section=================*/
@media screen and (max-width: 768px) {
  /*------------social media logo-------------*/
  .social-media-logo {
    position: absolute;
    bottom: 8px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    left: 45%;
    transform: translate(-50%, -50%);
  }
  /*=================otp section============*/
  /*---------otp form container---------*/
  .otp-form-container {
    margin: 2%;
    align-items: flex-start;
  }
  /*-------------otp form---------*/
  .otp-form {
    width: 100%;
    margin: 5%;
  }
  /*-------------otp  verification text----*/
  .otp-form h4 {
    font-size: 25px;
  }
  /*------------otp msg------------*/
  .otp-form h5 {
    font-size: 12px;
  }
  /*-------------------text field otp section----------*/
  .text-field-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
  }
  /*-------------enter otp ---------*/
  .enter-otp {
    width: 15%;
    height: 15%;
    background: #e5e5e5;
    border-radius: 4px;
  }
  /*-----------verify btn-----------*/
  .verify-btn {
    font-size: 24px;
  }
}
