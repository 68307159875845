
 
/* @import url('http://fonts.cdnfonts.com/css/avenir-next-lt-pro');
 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Avenir Next LT Pro',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code { 
  font-family: 'Avenir Next LT Pro';
        
} */


@font-face {
  font-family: 'AvenirRegular';
  src:local("AvenirRegular"), url(./font/AvenirNextLTPro-Regular.otf);
}
@font-face {
  font-family: 'AvenirDemi';
  src: local("AvenirDemi"), url(./font/AvenirNextLTPro-Demi.otf);
}
@font-face {
  font-family:"AvenirBold";
  src:local("AvenirBold"), url(./font/AvenirNextLTPro-Bold.otf);
}
body,h1,h2,h3,h4,h5,h6,p,span {
  font-family: Avenir Next LT Pro; 
}