.promocode-container {
  background-color: #f6f6f6;
  min-height: 90vh !important;
}
.promocode-container .coupon-header {
  background-color: #fff;
  padding: 1rem;
}

.promocode-container .coupon-heading {
  font-size: 1rem !important;
  display: flex;
  align-items: center;
}
.promocode-container .coupon-heading span {
  font-family: AvenirRegular;
}

.promocode-container .back-arrow {
  margin-right: 0.6rem;
  font-size: 1.5rem;
  color: #707070;
  cursor: pointer;
}

.promocode-wrapper {
  padding: 1rem 0.4rem !important;
}

.promocode-container .search-box {
  margin: 1rem 0.5rem;
}
.promocode-container .search-field {
  width: 100%;
  background-color: #fff;
}

.promocode-container .promocode-wrapper .promocode-title {
  font-size: 1.2rem !important;
  font-family: AvenirBold !important;
  margin: 0.3rem 0.5rem;
}

.coupon-card {
  margin: 1rem 0.5rem;
}

.terms-conditions {
  display: flex;
  align-items: center;
}

.logo-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.logo-header .card-title {
  font-size: 1.4rem !important;
  font-family: AvenirDemi;
  position: absolute;
  left: 1.7rem;
  color: #122164;
}

.coupon-card .coupon-title {
  font-size: 1rem !important;
  font-family: AvenirDemi;
  margin: 0.5rem 0;
}

.coupon-card .terms-conditions {
  font-size: 1rem;
  font-family: AvenirRegular;
  color: #7b8bd4;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.coupon-card .tnc-icons {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  color: #7b8bd4;
}

.coupon-card .tnc-content-box {
  margin: 0.5rem 0 !important;
}
.coupon-card .tnc-content-box .tnc-content {
  color: #9c8f8f;
}

.coupon-box {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.coupon-box .coupon-code-button {
  border: 2px dashed #7b8bd4;
  color: #000;
}
.coupon-box .coupon-code {
  border: 2px dashed #122164;
  color: #000;
  padding: 0.6rem;
  border-radius: 5px;
}

.coupon-box .apply-coupon-button {
  font-size: 1rem;
  color: #122164;
  font-family: AvenirRegular;
}

.coupon-card .applicable-text {
  margin: 1rem 0;
  color: #7b8bd4;
}

.apply-button {
  cursor: pointer;
}
