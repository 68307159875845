* {
    margin: 0;
    padding: 0;
}

.MuiGrid-item{
    padding-left: 0px !important;
}

.GativanMotionPerformanceContainer {
    margin: 0 3%;
}

.HeadingContainer {
    height: 10vw;
}

.HeadingSection {
    display: inline-flex;
    margin-top: 4%;
    width: 100%;
    margin-bottom: 0;
    justify-content: baseline;

}



.Bullet {
    width: 14px;
    height: 38px;
    display: inline-block;
    padding: 1.5vh 0px;
    background-color: #122164;
    margin-left: 2%;
    margin-top: 0.4%;
}

.Heading {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0.01em;

    color: #000000;
}

.SubHeading {
    display: none;
}

.GativanMotionPerformanceImgContainer {
    width: 80%;
    height: auto;
    margin: 3%;
}

.GativanMotionPerformanceImgContainer Grid{
    padding-left: 0 !important;
}

.GativanMotionPerformanceImg img {
    width: 246px;
    height: 324px;
}

.GativanMotionPerformanceBoxContainer {
    width: 12vw;
    height: 100%;
    text-align: center;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
    border-radius: 8px;
    overflow: hidden;
}

.ImageContainer {
    width: 100%;
    height: 70%;
    padding: 10%;
}

.ImageContainer img {
    width: 90%;
}

.TextContainer {
    width: 100%;
    height: 40%;
    background-color: #122164;
    padding: 3% 10%;
}

.TextContainer h4 {
    font-family:'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.01em;

    color: #FFFFFF;

}





@media screen and (max-width:768px) {
    .Bullet {
        width: 8px;
        height: 15px;
        background: #122164;
    }

    .HeadingContainer {
        height: 20vw;
    }

    .Heading { 
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        letter-spacing: 0.01em;

        color: #000000;
    }

    .SubHeading {
        display: block;
    }

    .SubHeading p {
        height: 100%;
        font-family: 'Avenir Next LT Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-left: 25px;
    }

    .GativanMotionPerformanceImgContainer {
        width: 90%;
        height: auto;
        padding: 3%;
        margin: 0;
    }

 

    .GativanMotionPerformanceBoxContainer {
        width: 40vw;
        height: 53vw;
        text-align: center;
        box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 10%;
    }

    .ImageContainer {
        width: 20vw;
        height: 40vw;
        padding: 10%;
    }

    .ImageContainer img {
        width: 34vw;
        height: 34vw;
    }

    .TextContainer {
        width: 100%;
        height: 65px;
        background-color: #122164;
        padding: 3% 15%;
    }

    .TextContainer h4 {
        color: #ffff; 
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.01em;
    }
}

/* media query for laptop and tablet */
@media screen and (max-width:1440px) and (min-width:768px) {
    .TextContainer h4{
        font-size: 16px;
        line-height: 20px;
    }
}