* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
/*===============Edit profile container============*/
.edit-Profile-container {
  margin: 8% 25%;
  background-color: #f8f8f8;
  position: relative;
  text-align: center;
  height: 600px;
  border-radius: 8px;
}
/*----------------avatar name-------------*/

.avatar-name {
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.avatar-name h4 {
  text-align: center;
  margin: 2% 0%;
}
/*-------------Personal details-----------*/

.personal-details {
  position: absolute;
  top: 45%;
  left: 46%;
  width: 50%;
  height: 300px;
  transform: translate(-50%, -50%);
}

.personal-details h3 {
  text-align: left;
  float: left;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.01em;
  margin-top: 2%;
  color: #000000;
}
/*-------------enter text ----------*/
.text-field {
  width: 120%;
  background: #e5e5e5;
  border-radius: 4px;
  margin-top: 3% !important;
}
.text-name {
  text-align: left;
  margin-top: 5% !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;

  color: #707070;
}
/*---------------edit and logout button----------*/

.edit-logout-btn {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
}
.edit-btn {
  background-color: #122164 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  letter-spacing: 0.02em !important;
  color: white !important;
  text-transform: none !important;
}
.logout-btn {
  background-color: #ff1a1a !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  letter-spacing: 0.02em !important;
  color: white !important;
  text-transform: none !important;
  margin-left: 5% !important;
}
/*====================================Mobile View started================================================*/
@media screen and (max-width: 768px) {
  .edit-Profile-container {
    margin: 35% 4%;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  /*----------personal details -----------*/
  .personal-details h3 {
    text-align: left;
    float: left;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 31px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 5%;
  }
  .personal-details {
    left: 30% !important;
  }
  .personal-details h3 {
    margin-top: 5%;
  }
  /*-----------enter text field name----------*/
  .text-field {
    width: 180% !important;
    background: #e5e5e5;
    border-radius: 4px;
    margin-top: 5%;
  }
  .text-name {
    text-align: left;
    margin-top: 15% !important;
  }
  /*-------------edit and logout button-----*/
  .edit-logout-btn {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-top: 5%;
    width: 100%;
  }
  .edit-btn {
    background-color: #122164 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 137.19% !important;
    letter-spacing: 0.02em !important;
    color: white !important;
    text-transform: none !important;
    width: 100%;
    height: 4vh;
  }
  /*-------------logout btn----------*/
  .logout-btn {
    background-color: #ff1a1a !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 137.19% !important;
    letter-spacing: 0.02em !important;
    color: white !important;
    text-transform: none !important;
    margin-left: 5% !important;
    width: 150px;
  }
}
