.shareJobContainer {
    margin: 2% 5%;
    padding: 1% 2%;
    background-color: #122164;
    width: 900px; 
    display: flex;
    flex-direction: column;
    align-self: center;
}

.download-container {     
display: flex;
justify-content: center;   
}

.icon {
    width: 130px;
    padding: 0px 5px;
    height: 40px;
    border-radius: 8px;
    background-color: #4CC713;
    margin-bottom: 20px; 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    text-decoration: none;
    border: none;
    cursor: pointer;
}



 

@keyframes download {
    0% {
        top: -60%;
    }

    100% {
        top: 16%;
    }
}


.shareJobcardHeader {
    background-color: #122164;
    padding: 2% 0%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shareJobcard-header-heading {
    font-size: 20px;
    color: white;
    font-family: 'Avenir Next LT Pro';
}

.Gativanlogo {
    width: 150px;
}

.Gativanlogo img {
    width: 100%;
}

.customerInfo-container {
    background-color: white;
    display: flex;
    height: 230px;
    padding: 1%;
}

.userDetail {
    width: 50%;
}

.label {
    font-size: 14px;
    font-family: 'Avenir Next LT Pro';
    margin-top: 7px;
}

.fields {
    color: #6a7086;
    font-size: 14px;
    font-family: 'Avenir Next LT Pro';
    font-weight: 600;
    margin-top: 5px;
}

.vehicle-image {
    width: 50%;
    display: flex;
    justify-content: center;

}



.shareJobcardSubHeader {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    margin: 7px 0px;
}

.shareJobcard-subHeader-heading {
    font-size: 16px;
    color: white;
    font-weight: 800;
}

.GativanSubLogo {
    height: 100%;
    display: flex;
    align-items: center;
}

.GativanSubLogo img {
    height: 100%;
}

.serviceContainer {
    background-color: white;
    padding: 1%;
}

.packageName {
    font-size: 16px;
    color: #122164;
    font-weight: 900;
    position: relative;
}

.packageName::after {
    content: '';
    position: absolute;
    left: 0%;
    bottom: 0%;
    width: 150px;
    height: 2px;
    background-color: black;
}

.services-activity {
    margin: 10px 0px;
    padding-left: 5px;
}

.services-activity-list {
    color: #6a7086;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.activityName {
    width: 85%;
    font-size: 12px;
    font-family: 'Avenir Next LT Pro';
}

.check_Image {
    height: 20px;
    width: 20px;
}

.subtotalSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}

.priceSection {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rupee_Image {
    width: 15px;
    height: 15px;
    font-family: 'Avenir Next LT Pro';
}

.subtotalPrice {
    font-size: 14px;
    font-family: 'Avenir Next LT Pro';
    color: gray;
}

.services-bottomBorder {
    width: 102%;
    height: 1.5px;
    background-color: gray;
    margin-left: -1%;
    margin-top: 2px;
    margin-bottom: 5px;

}

.subtotalSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}

.discountHeading {
    font-size: 14px;
    font-family: 'Avenir Next LT Pro';
    color: gray;
}

.discountPrice {
    font-size: 14px;
    color: gray;
}

.discountTopBorder {
    width: 100%;
    height: 1px;
    background-color: gray;
    margin: 5px 0px;
}

.termsAndCondSection {
    margin: 10px 0px;
}

.termsAndConHeading {
    color: white;
    font-size: 14px;
    font-family: 'Avenir Next LT Pro';
    margin-bottom: 10px;
}

.termsList {
    font-size: 12px;
    font-family: 'Avenir Next LT Pro';
    margin-bottom: 3px;
}

@media screen and (max-width:768px) {
    .shareJobContainer {
        margin: 2%;
        width:96vw;
    }

    .shareJobcardHeader {
        height: 50px;
    }

    .shareJobcard-header-heading {
        font-size: 16px;
    }

    .Gativanlogo {
        width: 100px;
    }

    .customerInfo-container {
        height: auto;
        padding-bottom: 10px;
    }

    .label {
        font-size: 12px;
        font-family: 'Avenir Next LT Pro';
    }

    .fields {
        color: #6a7086;
        font-size: 12px;
        font-weight: 600;
    }

    .packageName {
        font-size: 14px;
    }

    .packageName::after {
        width: 100px;
    }

    .activityName {
        font-size: 10px;
    }

    .check_Image {
        height: 15px;
        width: 15px;
    }

    .rupee_Image {
        width: 12px;
        height: 12px;
    }

    .subtotalPrice {
        font-size: 11px;
    }

    .termsAndConHeading {
        font-size: 12px;
    }

    .termsList {
        font-size: 10px;
    }

     
    .icon{
        width: 80px;
        height: 35px; 
    }

    .icon h5{
        font-size: 12px;
    }


}