.viewCart-mainCotainer {
  /* padding: 2%; */
  background-color: white;
}

.view-cart-container {
  margin: 3% 2% 0;
}

/* ------------services------------------- */
.viewcart-dekstop-cart-icon {
  display: none;
}

.added-services-each-service {
  padding: 3%;
}

.viewCart-added-services {
  border: var(--border);
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
}

.added-service-image {
  width: 104px;
  height: 91px;
  position: relative;
}

.added-service-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

/* ------old price----------- */

.oldPrice {
  color: #747474;
  position: relative;
  font-size: 3.5vw;
  font-family: AvenirRegular;
}

.oldPrice-rupeeIcon {
  font-size: 3.5vw !important;
}

.oldPrice::after {
  content: "";

  width: 100%;
  height: 1px;
  background-color: #747474;
  position: absolute;
  /* left: 0%; */
  right: 0%;
  transform: rotateZ(10deg);
  top: 45%;
}

.added-serviceName {
  font-size: 5vw !important;
  font-weight: 700 !important;
  color: #121212;
  font-family: AvenirBold;
}

.added-serviceDesc {
  margin-top: 1%;
  font-size: 3vw;
  font-weight: 400 !important;
  color: #707070;
}

/* -------------New Price---------------------- */

.newPrice {
  font-size: 5vw;
  color: #000;
  margin-left: 3%;
  font-weight: 600;
  font-family: AvenirBold;
}

.newPrice-rupeeIcon {
  font-size: 3.5vw !important;
  font-weight: 600 !important;
}

/* ---------------Delete Button---------- */
.added-service-delete-btn {
  padding-top: 3%;
}

.added-service-delete-btn .delete-btn {
  color: red !important;
  font-size: 6vw;
  transition: 0.5s !important;
  border-radius: 25px;
  padding: 2px;
}

.added-service-delete-btn .delete-btn-dekstop {
  display: none;
}

.added-service-delete-btn .delete-btn:active {
  scale: 1.5 !important;
  background-color: rgb(248, 131, 131);
  padding: 5px;
}

/* --------------------------------------------------- */

.service-instruction {
  border-top: 1px solid #e5e5e5;
  position: relative;
  height: 80px;
}

.service-instruction .input {
  font-family: AvenirRegular;
  position: absolute;
  font-size: 4vw !important;
  height: 100% !important;
  width: 100%;
  padding: 2.5%;
  border: none;
  outline: none !important;
}

/* ----------------------recommended-services------------------------------ */

.viewcart-recommended-services {
  width: 100%;
  margin-top: 5%;
  /* height: 250px; */
  border-radius: 6px;
  border: var(--border);
  padding: 2%;
  background: #f8f8f8;
}

.bulb {
  padding: 2%;
  background-color: rgba(143, 141, 141, 0.411);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulb img {
  position: absolute;
  height: 80% !important;
  width: 96%;
}

.viewcart-recommended-servicesh4 {
  font-size: 4vw;
  font-weight: 700;
  /* margin-left: 5%; */
}

.viewcart-recommended-services-heading h4 {
  color: #121212;
  font-family: AvenirBold;
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 137.188%;
  letter-spacing: 0.32px;
}

.viewcart-recommended-services-heading p {
  color: #666;
  font-family: AvenirRegular;
  font-size: 3vw !important;
  font-style: normal;
  font-weight: 400;
  line-height: 137.188%;
  /* 16.463px */
  letter-spacing: 0.24px;
}

.viewcart-recommended-services-heading h4 {
  color: #121212;
  font-family: AvenirBold;
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 137.188%;
  letter-spacing: 0.32px;
}

.viewcart-recommended-services-heading p {
  color: #666;
  font-family: AvenirRegular;
  font-size: 3vw !important;
  font-style: normal;
  font-weight: 400;
  line-height: 137.188%;
  /* 16.463px */
  letter-spacing: 0.24px;
}

.viewcart-recommended-services p {
  font-size: 3vw;
}

.recommended-services-carousel {
  width: 100%;
  height: 20vh;
  margin-top: 2%;
  /* background-color: red; */
  position: relative;
}

.recommended-services-carousel .carousel {
  width: 100%;
  height: 100%;
  position: absolute;
}

.recommended-servic-card {
  width: 63vw;
  /* margin-left: 15px; */
  padding: 2% 1% 2% 3%;

  border-radius: 10px;
  border: 1px solid #e5e5e5;
  background: #fff;
}

.recommended-card-image {
  width: 75%;
  height: 75%;
}

.recommended-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.recommended-card-title {
  margin-left: 3%;
}

.recommended-card-title h4 {
  font-size: 4vw;
  color: #121212;
  /* text-align: center; */
  font-family: AvenirBold;
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 700;
  line-height: 137.188%;
  /* 21.95px */
  letter-spacing: 0.32px;
}

.recommende-image-title {
  height: 14vh;
}

.recommended-card-desc {
  margin-top: 3%;
}

.recommended-card-desc span {
  /* font-size: 2.5vw !important; */
  font-size: 10px !important;
  color: #666;
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  line-height: 137.188%;
  letter-spacing: 0.2px;
}

.recommended-card-desc .desc {
  font-size: 2.5vw !important;
  color: #666;
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  line-height: 137.188%;
  letter-spacing: 0.2px;
}

.recommended-servic-card .oldPrice,
.recommended-servic-card .newPrice {
  display: flex;
  align-items: center;
}

.recommended-servic-card .oldPrice,
.recommended-servic-card .oldPrice-rupeeIcon {
  font-size: 2.5vw !important;
}

.recommended-servic-card .newPrice,
.recommended-servic-card .newPrice-rupeeIcon {
  font-size: 3.5vw !important;
}

.recommended-servic-card .add-service-btn {
  font-size: 2.5vw !important;
  border-radius: 4px;
  border: 1px solid #8c8c8c;

  color: #122164;
  font-family: AvenirDemi;
  font-style: normal;
  font-weight: 600;
  line-height: 137.188%;
  text-transform: capitalize;
}

.carousel .carousel-item-margin-40-px {
  /* margin-right: 45px; */
  width: 65vw !important;
}

/* --------------------promocode------------------------------------------------- */

.promocode {
  margin-top: 2%;
  padding: 2.5% !important;
  border: var(--border);
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.promocode-selection {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start;
}

.promocode-selection h6 {
  font-size: 3.5vw;
  font-weight: 600;
}

.promocode-rightArrow {
  font-size: 5vw;
}

/* ---------------------Bill Details-------------------------------- */

.bill-detail {
  margin-top: 3% !important;
  background-color: #e3e7fa;
  border: var(--border);
  border-radius: 6px;
  padding: 2%;
}

.bill-detail h5 {
  font-family: AvenirBold;
  font-size: 4vw;
  font-weight: 700;
}

.bill-sub-total,
.bill-you-pay,
.you-pay-later {
  display: flex;
  justify-content: space-between;
}

.bill-sub-total h6 {
  font-family: AvenirRegular;
  margin-top: 2%;
  font-size: 3vw;
  color: #121212;
  font-weight: 400 !important;
  display: flex;
}

.you-pay-later h6 {
  font-family: AvenirDemi;
  margin-top: 2%;
  font-size: 4vw;
  color: #121212;
  font-weight: 400 !important;
  display: flex;
}

.bill-sub-total h6 .bill-rupee-icon {
  font-size: 3.1vw !important;
}

.you-pay-later h6 .bill-rupee-icon {
  font-size: 3.1vw !important;
}

.bill-you-pay h6 {
  font-family: AvenirDemi;
  margin-top: 2%;
  font-size: 3vw;
  font-weight: 600;
  display: flex;
}

.bill-you-pay .bill-rupee-icon {
  font-size: 4vw !important;
  margin-top: 1%;
}

/* ------------------- empty box ----------------- */
.empty-box {
  height: 52vh;
}

/* -----------view cart footer---------------------- */

.viewcart-footer {
  margin-top: 2%;
  border: var(--border);
  background-color: var(--blue);
  border-radius: 6px;
  padding: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0px !important;
}

.viewcart-footer-total-items-dekstop {
  display: none;
}

.viewcart-footer-total-items {
  text-align: left;
}

.viewcart-footer-total-items .added-items {
  font-weight: 600;
  color: #fff;
  font-size: 2.8vw;
  margin-left: 4%;
}

.viewcart-footer-total-items h6 {
  color: #fff;
  font-weight: 600;
  font-size: 4.5vw;
}

.footer-total-rupee-icon {
  font-size: 3.8vw !important;
  margin: 0%;
}

.viewcart-footer-youpay {
  display: none;
  /* hidden in mobile view */
}

.viewcar-footer-checkout-btn .checkout-button {
  background-color: white !important;
  color: var(--blue);
  text-transform: capitalize;
  font-weight: 500;
}

.Dekstop-Viewcart-footer {
  display: none;
}

.mobile-view-footer {
  position: sticky !important;
  bottom: 0px !important;
}

/* --------------------promocode------------------------------------------------- */

.promocode {
  margin-top: 2%;
  padding: 2.5% !important;
  border: var(--border);
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.promocode-selection {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  padding: 1%;
}

.promocode-selection .promocode-heading {
  display: flex;
  align-items: center;
}

.promocode-selection .promocode-heading span {
  font-family: AvenirBold !important;
  font-size: 1rem !important;
}

.promocode-selection .promocode-heading .offer-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  color: #122164;
}

.promocode-selection .promocode-heading .coupon-image {
  width: 30px;
  height: 30px;
  color: #122164 !important;
  margin-right: 0.6rem;
}

.promocode-rightArrow {
  font-size: 1.5rem !important;
}

.remove-promocode {
  font-size: 1rem !important;
  background-color: hsl(0, 0%, 60.8%, 0.2);
  border-radius: 50%;
  cursor: pointer;
  /* color: #fff; */
}

.coupon-discount-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coupon-discount-label {
  color: #51ae3a;
  font-size: 0.8rem;
  font-family: AvenirRegular;
}

.coupon-discount {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #51ae3a;
  font-family: AvenirRegular;
}

.coupon-discount .coupon-discount-rupee-icon {
  font-size: 0.9rem;
  color: #51ae3a;
}

/* -----------------------Dekstop------------------------------------------- */

@media screen and (min-width: 768px) {
  .Dekstop-Viewcart-footer {
    display: block;
  }

  .mobile-view-footer,
  .empty-box {
    display: none;
  }

  .viewCart-mainCotainer {
    margin: 1%;
    padding: 0% !important;
    background-color: #fff;
    box-shadow: 1px 1px 20px rgba(5, 5, 5, 0.1) !important;
  }

  .viewCart-added-services {
    /* padding: 4%; */
    border: none !important;
  }

  .viewcart-dekstop-cart-icon {
    display: block;
    font-size: 2.5vw;
    display: flex;
    align-items: center;
  }

  .added-services-container {
    padding: 0% 4%;
    border-bottom: 1px solid #e5e5e5;
  }

  .added-services-each-service {
    border-bottom: 1px solid #e5e5e5;
    padding: 2% 0% !important;
  }

  .viewcart-dekstop-cart-icon .viewcart-shopping-cart {
    font-size: 2.2vw;
    margin-left: 4%;
  }

  .added-service-image {
    display: none;
  }

  .added-service-details {
    display: flex;
    flex-direction: row !important;
    position: relative;
  }

  .added-service-details .added-serviceDesc {
    display: none;
  }

  .added-service-details .oldPrice {
    display: none;
  }

  .added-service-details .added-serviceName {
    font-size: 1vw !important;
    font-weight: 600 !important;
  }

  .added-service-details .newPrice {
    font-size: 1vw;
    color: #606060;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    position: absolute;
    left: 95%;
  }

  .added-service-details .newPrice-rupeeIcon {
    font-size: 1vw !important;
  }

  .added-service-delete-btn {
    padding: 0%;
    margin-top: 1%;
    display: flex;
    align-items: flex-end;
  }

  .added-service-delete-btn .delete-btn {
    display: none;
  }

  .added-service-delete-btn .delete-btn-dekstop {
    font-size: 1vw;
    font-weight: 700;
    color: black !important;
    display: block;
    color: #e3e7fa;
    background-color: rgba(128, 128, 128, 0.37);
    /* overflow: hidden; */
    border-radius: 20px !important;
  }

  .added-service-delete-btn .delete-btn-dekstop:active {
    background-color: red !important;
  }

  .service-instruction {
    margin: 5% 4%;
    min-height: 25px !important;
    border: var(--border);
  }

  .service-instruction .input {
    font-size: 1vw !important;
  }

  /* --------------------Recommended services */

  .viewcart-recommended-services {
    height: 270px !important;
    width: 100% !important;
    border-radius: 0px !important;
  }

  .recommende-image-title {
    height: 9vh;
  }

  .viewcart-recommended-services h4 {
    font-size: 1.2vw;
  }

  .viewcart-recommended-services p {
    font-size: 0.8vw !important;
  }

  .recommended-services-carousel {
    height: 75% !important;
  }

  .viewcart-recommended-services p {
    font-size: 0.8vw !important;
  }

  .recommended-card-title h4 {
    font-size: 0.8vw !important;
    color: #121212;
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    line-height: 137.188%;
    /* 21.95px */
    letter-spacing: 0.32px;
  }

  .recommended-card-desc .desc {
    font-size: 0.5vw !important;
    color: #666;
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    line-height: 137.188%;
    letter-spacing: 0.2px;
  }

  .recommended-servic-card {
    margin-left: 10px !important;
    border: var(--border);
    width: 17vw;
    height: 100% !important;
    margin-left: 0px;
    border-radius: 6px;
    padding: 2% 0% 2% 2%;
  }

  .recommended-servic-card .oldPrice {
    font-size: 0.7vw !important;
  }

  .oldPrice .oldPrice-rupeeIcon {
    font-size: 0.8vw !important;
    margin-top: 10%;
  }

  .recommended-card-title h4 {
    font-size: 0.8vw !important;
    color: #121212;
    font-family: AvenirBold;
    font-style: normal;
    font-weight: 700;
    line-height: 137.188%;
    /* 21.95px */
    letter-spacing: 0.32px;
  }

  .recommended-card-desc .desc {
    font-size: 0.5vw !important;
    color: #666;
    font-family: AvenirRegular;
    font-style: normal;
    font-weight: 400;
    line-height: 137.188%;
    letter-spacing: 0.2px;
  }

  .recommended-card-image {
    width: 40%;
    height: 80%;
  }

  .recommended-servic-card .newPrice {
    font-size: 1vw !important;
  }

  .newPrice .newPrice-rupeeIcon {
    font-size: 0.8vw !important;
    margin-top: 10% !important;
  }
  .recommended-servic-card .add-service-btn {
    /* padding: 0.1% !important; */
    font-size: 0.7vw !important;
    font-weight: 400 !important;
    padding: 5px !important;
    /* border-radius: 0px; */

    display: flex;
    /* width: 85px;
       height: 31px; */
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }

  .carousel .carousel-item-margin-40-px {
    /* margin-right: 45px; */
    width: 18vw !important;
  }

  /* --------------promocode--------------------------- */

  .promocode-selection h6 {
    font-size: 1vw;
  }

  .carousel .carousel-item-margin-40-px {
    /* margin-right: 45px; */
    width: 18vw !important;
  }

  /* --------------promocode--------------------------- */

  .bill-detail h5 {
    font-size: 1vw;
  }

  .bill-sub-total h6 {
    font-size: 1vw !important;
    display: flex;
    align-items: center;
  }

  .you-pay-later h6 .bill-rupee-icon {
    font-size: 0.9vw !important;
    font-family: AvenirDemi !important;
  }

  .you-pay-later h6 {
    font-size: 1vw !important;
    display: flex;
    align-items: center;
  }

  .bill-sub-total h6 .bill-rupee-icon {
    font-size: 0.9vw !important;
    font-family: AvenirDemi !important;
  }

  .bill-you-pay h6 {
    font-size: 1vw;
    display: flex;
    align-items: center;
  }

  .bill-you-pay h6 .bill-rupee-icon {
    font-size: 0.9vw !important;
  }

  /* -------view Cart Footer */

  .viewcart-footer-total-items-dekstop {
    display: block;
  }

  .viewcart-footer-youpay {
    display: block;
    color: #fff;
    font-size: 1vw;
  }

  .viewcart-footer-youpay .rupee-icon {
    font-size: 1vw;
    font-weight: 700;
  }

  .checkout-button {
    display: none !important;
  }

  .viewcart-footer-total-items {
    display: none;
  }

  .viewcart-footer-total-items-dekstop h6 {
    font-size: 1.2vw;
    color: #fff;
  }

  .footer-total-rupee-icon {
    font-size: 0.8vw !important;
  }

  .side-drawer {
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 35vw;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.5s ease-out;
  }
  .side-drawer.open {
    transform: translateX(0);
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
  }
  .viewcart-footer-total-items {
    display: none;
  }
}
