.sidebar {
    float: left;
    width: 20%;
    background-color: #ffffff;
    border-right: 0.5px solid #E5E5E5;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); */
}


.sidebar-link {
    color: #000000;
    text-decoration: none !important;
}

.sidebar .css-10hburv-MuiTypography-root, .sidebar .css-yb0lig {
    font-weight: 600 !important;
}

.main-content {
    width: 80%;
}

.policy-container {
    display: flex;
}

@media screen and (max-width:768px) {
    .sidebar {
        display: none;
    }

    .main-content {
        width: 100%;
    }
}
