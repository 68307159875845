* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.view_details_container {
  grid-template-columns: 100%;
  grid-template-rows: 150px 130px auto;
  background-color: #ffffff;
  padding: 0 3%;
}

.heading {

  font-family: AvenirBold;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  /* identical to box height, or 22px */

  text-align: center !important;
  letter-spacing: 0.02em !important;

  color: #121212;
  margin: 3% 0% !important;
}

.view-details-divider {
  width: 10% !important;
  height: 5px !important;
  background-color: black !important;
}

/*-------------------service_details---------*/
.service-detalis-section {
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  background-color: #ffffff;
  height: 100%;
  padding: 2%;
}



.service-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: .12px;
  text-align: left;
  color: #000;
}

.service-details-heading {
  font-family: AvenirRegular;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 137.19% !important;
  /* or 16px */

  text-align: center !important;
  letter-spacing: 0.02em !important;

  color: #121212 !important;



  margin-left: 2% !important;
  font-stretch: normal !important;
  /* line-height: 1.54 !important; */
  /* text-align: left !important; */
  /* color: #000 !important; */
}

/*-----------Special offer-------*/
.special_offer {
  height: 100px;
  background-color: #ffffff;
  margin-top: 2%;
}

/*-----------Whats includes-------*/
.whats_included {
  /* margin-top: 2%; */
  background-color: #ffffff;
}

/* ------------- service activities ------------------ */
.service-activities {
  /* margin-top: 3%; */
  padding: 0% 5%;

}

.service-activities p {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 137.19%;
  /* or 16px */


  color: #000000;

  display: flex;
  padding: 1.5% 0;
}

/* .homepage-headers h2{
  margin-top: 0px;
  margin-left: 20px;
} */

/*---------------Essential Services-----------*/
.essential-image {
  width: 100%;
  height: 100%;
  padding-right: 5% !important;
}

.essential-image-text {
  display: block;
  flex-direction: column;
}

.essential-service-name {
  font-size: 13px !important;
  text-align: justify !important;
  margin: 2% 5% !important;
  font-weight: 600 !important;
  line-height: 1 rem !important;
}

/*---------------additional services-----------*/
.additional-services-section {
  margin-top: 2%;
  background-color: #ffffff;
}

.additinal-services {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: .14px;
  letter-spacing: .00875rem;
  text-align: left;
  color: grey;
  margin-top: 3%;
}

.view-details-additional-service {
  margin-left: 5% !important;
}

/*-----------Warranty -------*/
.warranty {
  height: 250px;
  background-color: #707070;
  margin-top: 2%;
}

/*-----------FAQ -------*/
.faq {
  margin-top: 2%;
  background-color: #ffffff;
}

/*-----------Rating and Review -------*/
.rating_review {
  background-color: #ffffff;
  margin-top: 2%;
}

.viewdetails-review-card {
  background-color: #e3e7ea;
  margin-top: 2%;
  padding: 2%;
  border-radius: 8px;

}

/*----------- benefits -------*/
.benefits {
  background-color: #ffffff;
  margin-top: 2%;
  padding: 5%;
}

.gativan-benefits {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/*------------top links-------*/
.top_links {
  background-color: #ffffff;
  margin-top: 2%;
  padding: 5%;
}

/*--------------addservice----------*/
.addservice {
  background-color: #ffffff;
  margin-top: 2%;
  padding: 3%;
}

.select-service {
  background-color: #122164 !important;
  color: #ffffff !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  width: 100% !important;
  text-decoration: none !important;
  text-transform: none !important;
}

.commonMenu-checkIcon {
  display: block;
  font-size: 15px !important;
  margin-top: 3px;
  margin-right: 5px;
  color: #51AE3A;
}



.gativan-benefits {
  background-color: #ffffff;
}