 

.GativanBenefitsContainer {
    margin: 0 3%;  
    margin: 0% 3%;
    width: 90%;
}

.HeadingSection {
    display: inline-flex;
    margin-top: 4%;
    width: 100%;
    margin-bottom: 0;
    justify-content: baseline;

}

.Bullet {
    width: 14px;
    height: 38px;
    display: inline-block;
    padding: 1.5vh 0px;
    background-color: #122164;
    margin-left: 2%;
    margin-top: 0.4%;
}

.Heading {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0.01em;
    color: #000000;
}
 
 

.GativanBenefitsImgContainer {
 
    height: auto;
    background-color: #7B8BD4; 
    margin-left: 24px;
    margin-top: 2%;
    align-items: center;
    padding: 2%;

}

.Benefitscontainer {
    align-items: center;
    text-align: center;
    padding: 2%;
}

.Benefitsimg {
    align-items: center;
    text-align: center;
    padding: 2%;

}

.Benefitsdesp p{
    font-family: AvenirDemi;
    text-align: center;
    color: #FFFF;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    letter-spacing: 0.01em;
    line-height: 31px;
    padding: 5% 20% 0% 15%;

}

/****************************Mobile Gativan Benefits Css ******************/

@media screen and (max-width:600px) {
  
    .GativanBenefitsImgContainer {
        background-color: #7B8BD4;
        border-radius: 10px;
        align-items: center;
        /* margin-left: 5%; */
        width: 92%;
        height: 50%;
        margin:4%;


    }
    .GativanBenefitsContainer{
        margin: 0%;
    }

    .Benefitscontainer {
        align-items: center;
        text-align: center;
        padding: 5%;
        margin-left: 15%;
    }

    .Benefitsimg {
        align-items: center;
        text-align: center;


    }

    .Benefitsdesp p {

        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        font-size: 16px !important;
        text-align: 'center';
        letter-spacing: 0.01em;
        color: #FFFF;
        padding: 6% 0% 0% 0%;
        align-items: center;


    }

    .GativanBenefitsImgContainer img {
        text-align: 'center';
        align-items: center;
        width: 36px;
        height: 41px;


    }

}