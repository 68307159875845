* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.img-container {
  position: relative;
  text-align: center;
  color: white;
}

.login-form {
  margin: auto;
  align-items: center;
  justify-content: center;
  display: block;
}

.login-background {
  width: 100%;
}

.gativan-logo {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 50% !important;
}

.social-media-logo {
  position: absolute;
  bottom: 10%;
  left: 16px;
  padding: 2%;
  display: flex;
  justify-content: space-between;
}

.twitter-logo {
  margin-left: 8%;
}

.instagram-logo {
  margin-left: 8%;
}

.confirm-btn {
  background-color: #7b8bd4 !important;
  color: white !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 137.19% !important;
  letter-spacing: 0.02em !important;
  color: white !important;
  text-transform: none !important;
  width: 100% !important;
  height: 50px !important;
}

.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  width: 100%;
  margin: 5%;
}

.login-form h4 {
  font-family: AvenirBold;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: left;
}

.login-form h5 {
  font-family: AvenirRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 137.19%;
  color: #121212;
  text-align: left;
}

.enter-mobile-no {
  font-family: AvenirRegular;
  background: #f0f0f0;
  width: 100%;
}

.text-field-mobile {
  margin: 5% 0%;
}

.confirm-btn {
  font-family: AvenirBold !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 137.19% !important;
  width: 100% !important;
  /* height: 60% !important; */
  color: #fafafa !important;
}

.sign-up {
  width: 100%;
  text-align: center;
}

.back-to-booking {
  position: absolute;
  top: 70px !important;
  left: 100px !important;
  display: flex;
  align-items: center;
}

/*=======================Mobile View Started=================*/
@media screen and (max-width: 768px) {
  .login-form-container {
    margin: 2%;
    align-items: flex-start;
    padding: 2% !important;
  }

  .login-form {
    width: 100%;
    margin: 5%;
  }

  .back-to-booking {
    top: 10px !important;
    left: 35px !important;
  }

  .gativan-logo {
    top: 40% !important;
    left: 50% !important;
    width: 65% !important;
  }

  .login-form h4 {
    font-size: 35px;
  }

  .login-form h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 137.19%;
    /* identical to box height, or 22px */


    color: #707070;
  }

  .confirm-btn {
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 137.19%;
    /* identical to box height, or 22px */


    color: #FAFAFA;
  }

  .social-media-logo {
    width: 35% !important;
    position: absolute;
    bottom: 8px;
    left: 16px;
    padding: 2%;
    display: flex;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}